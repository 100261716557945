import { DoubleModal } from '@components'
import {
  SuggestionForm,
  SuggestionFormProps
} from '@pages/DocumentPage/DocumentDetails/AccountingSuggestions/SuggestionForm'
import { DocumentFormExtraDetailView } from '@pages/DocumentPage/DocumentForm/DocumentFormExtraDetailView'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends SuggestionFormProps {
  isVisible: boolean
}

export const SuggestionFormModal: React.FC<Props> = ({
  suggestion,
  onCreate,
  isVisible,
  onClose
}) => {
  const { t } = useTranslation()
  const [selectedAttachments, setSelectedAttachments] = useState<number[]>()

  return (
    <DoubleModal
      isVisible={isVisible}
      oneModalWidthMin={480}
      oneModalWidthMax={1000}
      height={1100}
      mainHeader={t('document.createDocument')}
      mainContent={
        <SuggestionForm
          suggestion={suggestion}
          onCreate={onCreate}
          onClose={onClose}
          onValuesUpdate={data => {
            setSelectedAttachments(data?.attachment_ids || [])
          }}
        />
      }
      onClose={onClose}
      secondaryContent={
        <DocumentFormExtraDetailView
          importData={suggestion.import_data}
          attachmentIds={selectedAttachments}
        />
      }
    />
  )
}
