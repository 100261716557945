import { IDocument } from '@query'
import styled from 'styled-components'
import { LuClock12 } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'

interface Props {
  document: IDocument
}

export const AlgorithmStatusBadge: React.FC<Props> = ({ document }) => {
  const { t } = useTranslation()

  if (document?.import_data?.algo_status == 'RUNNING') {
    return (
      <BaseBadge className={'running'}>
        <AnimatedClock>
          <LuClock12 className="hours" />
          <LuClock12 className="minutes" />
        </AnimatedClock>
        <span>{t('document.algoRunning')}</span>
      </BaseBadge>
    )
  }
}

const BaseBadge = styled.div`
  width: fit-content;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-top: ${({ theme }) => theme.spacing.xxs}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;

  &.running {
    color: ${({ theme }) => theme.colors.metalGray};

    & svg {
      color: ${({ theme }) => theme.colors.metalGray};
    }
  }
`

const AnimatedClock = styled.div`
  position: relative;
  width: ${({ theme }) => theme.fontSize.sm}rem;
  height: ${({ theme }) => theme.fontSize.sm}rem;

  & > * {
    color: ${({ theme }) => theme.colors.metalGray};
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .hours {
    animation: rotate 2s infinite linear;
  }

  .minutes {
    animation: rotate 24s infinite linear;
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`
