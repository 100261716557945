import { useEffect } from 'react'

interface Settings {
  enabled: boolean
  interval: number
}

export const useInterval = (callback: () => void, settings: Settings) => {
  useEffect(() => {
    if (settings.enabled) {
      const interval = setInterval(() => {
        callback()
      }, settings.interval)

      return () => clearInterval(interval)
    }
  }, [callback, settings])
}
