import { NOTIFYABLE_ERROR_CODES } from '@constants'
import { i18nInstance } from '@localisation'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { toast } from 'react-hot-toast'
import {
  clearAuthenticationToken,
  getAuthenticationToken,
  setAuthenticationToken
} from '@utils/tokenUtils.ts'

export const BASE_URL = import.meta.env.VITE_NOCFO_BASE_URL

const DEFAULT_TIMEOUT = import.meta.env?.DEFAULT_TIMEOUT || 10000 // 10s
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json;charset=UTF-8'
}

const DEFAULT_HTTP_CLIENT_CONFIG: AxiosRequestConfig = {
  baseURL: BASE_URL,
  headers: DEFAULT_HEADERS,
  timeout: DEFAULT_TIMEOUT,
  paramsSerializer: params => qs.stringify(params, { indices: false }),
  validateStatus: status => {
    return status >= 200 && status < 300
  }
}

class Networking {
  httpClient: AxiosInstance

  constructor() {
    this.httpClient = axios.create(DEFAULT_HTTP_CLIENT_CONFIG)
    this.httpClient.interceptors.response.use(
      this.responseInterceptor,
      this.responseErrorInterceptor
    )

    this.rehydrateToken()
  }

  setAccessToken = (token: string) => {
    if (token) {
      setAuthenticationToken(token)
      this.httpClient.defaults.headers['Authorization'] = `Token ${token}`
    }
  }

  clearAccessToken = () => {
    clearAuthenticationToken()
    delete this.httpClient.defaults.headers['Authorization']
  }

  private rehydrateToken() {
    const token = getAuthenticationToken()
    this.setAccessToken(token)
  }

  private responseInterceptor = (response: AxiosResponse<any>) => {
    return response
  }

  private responseErrorInterceptor = (response: AxiosResponse<any>) => {
    // CASE: Predefined backend error cases that somewhat unrelated to users current actions
    if (NOTIFYABLE_ERROR_CODES.includes(response.status)) {
      if (response.status == 428) {
        toast.error(i18nInstance.t('error.upgradeRequired'))
      } else {
        toast.error(i18nInstance.t('error.oopsTitle'))
      }
      throw response
    }
    throw response
  }
}

export const network = new Networking()
