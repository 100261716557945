import { Button, PopupMenu, PopupMenuButton, PopupMenuContainer, PopupMenuItem } from '@components'
import React from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import styled from 'styled-components'

interface MenuItemProps {
  Icon?: any
  name?: string
  onClick?: () => any
  divider?: boolean
  intent?: any
  disabled?: boolean
}

interface Props {
  header?: string | React.ReactNode
  subHeader?: React.ReactNode
  menuItems?: MenuItemProps[]
}

export const HeaderBlock: React.FC<Props> = ({ header, subHeader, menuItems }) => {
  return (
    <StyledHeaderBlock>
      <HeaderWrapper>
        {header && <StyledHeader>{header}</StyledHeader>}
        {subHeader && <StyledSubHeader>{subHeader}</StyledSubHeader>}
      </HeaderWrapper>

      {menuItems && (
        <PopupMenuContainer>
          <PopupMenu menuGrow="right">
            {menuItems.map(
              ({ Icon, name, onClick, divider, intent = 'none', disabled = false }, index) => {
                if (divider) {
                  return <hr key={`divider-${index}`} />
                } else {
                  return (
                    <PopupMenuItem
                      key={`item-${index}`}
                      onClick={() => !disabled && onClick()}
                      icon={Icon && <Icon color={intent === 'danger' && 'red'} />}
                      title={name}
                      disabled={disabled}
                    />
                  )
                }
              }
            )}
          </PopupMenu>
          <PopupMenuButton>
            <Button
              data-test="invoice-actions-dropdown-button"
              isSecondary={true}
              icon={<FaEllipsisH />}
            />
          </PopupMenuButton>
        </PopupMenuContainer>
      )}
    </StyledHeaderBlock>
  )
}

const StyledHeaderBlock = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.md}rem;

  & > * {
    align-self: center;
  }
`

const HeaderWrapper = styled.div`
  flex: 1;
`

const StyledHeader = styled.h2`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin-bottom: 0;
`

const StyledSubHeader = styled.div``
