import React from 'react'
import {
  ActionButton,
  ContentContainer,
  Header,
  HeaderContainer,
  HeaderText,
  Page,
  StyledMenu
} from './WelcomePage.styled'
import VerticalCard from './VerticalCard'
import engineerSvg from '@assets/undraw/undraw_qa_engineers_dg-5-p.svg'
import helloSvg from '@assets/undraw/undraw_hello_re_3evm.svg'
import { Link, useHistory } from 'react-router-dom'
import { useScreen } from '@root/utils'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { createDemoBusiness, IBusiness, logOut } from '@root/query'
import toast from 'react-hot-toast'
import { HeaderBlock } from '@root/components'
import { FaSignOutAlt } from 'react-icons/fa'

export const WelcomePage: React.FC = () => {
  const { isMobile } = useScreen()
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const history = useHistory()

  // Case: Animations stretch the page which brings out the side scrollbar. This looks unprofessional.
  // > Hiding the scrollbar when animations are running.
  // source: https://github.com/motiondivision/motion/issues/1468
  const [isAnimating, setIsAnimating] = React.useState(false)

  const mutationOptions = {
    onSuccess: async data => {
      await queryClient.invalidateQueries('user')
      await queryClient.invalidateQueries(['businesses'])

      history.push(`/${data.business_id}/`)
    }
  }

  const createDemoMutation = useMutation<Partial<IBusiness>, unknown>(
    () => createDemoBusiness({ useMockData: true }),
    mutationOptions
  )

  const logOutMutation = useMutation(() => logOut(), {
    onSettled: async () => {
      queryClient.clear()
      await queryClient.invalidateQueries()
      window.location.assign('/')
    }
  })

  return (
    <Page
      onAnimationStart={() => setIsAnimating(true)}
      onAnimationComplete={() => setIsAnimating(false)}
      isAnimating={isAnimating}
    >
      <div>
        <StyledMenu isMobile={isMobile}>
          <HeaderBlock
            menuItems={[
              {
                name: t('welcomePage.logOut'),
                Icon: () => <FaSignOutAlt />,
                onClick: () => logOutMutation.mutate()
              }
            ]}
          ></HeaderBlock>
        </StyledMenu>
      </div>

      <HeaderContainer isMobile={isMobile}>
        <Header>{t('welcomePage.header')}</Header>
        <HeaderText>{t('welcomePage.headerText')}</HeaderText>
      </HeaderContainer>

      <ContentContainer isMobile={isMobile}>
        <VerticalCard
          svg={helloSvg}
          header={t('welcomePage.newCompanyCard.header')}
          body={t('welcomePage.newCompanyCard.body')}
          actionElement={
            <Link to="/onboarding">
              <ActionButton intent="success" disabled={createDemoMutation.isLoading}>
                {t('welcomePage.newCompanyCard.action')}
              </ActionButton>
            </Link>
          }
        ></VerticalCard>
        <VerticalCard
          svg={engineerSvg}
          header={t('welcomePage.demoCard.header')}
          body={t('welcomePage.demoCard.body')}
          actionElement={
            <ActionButton
              onClick={async () => {
                const promise = createDemoMutation.mutateAsync()

                toast.promise(promise, {
                  loading: t('demo.promise.loading'),
                  success: t('demo.promise.success'),
                  error: t('demo.promise.error')
                })
              }}
              disabled={createDemoMutation.isLoading}
              intent="default"
            >
              {t('welcomePage.demoCard.action')}
            </ActionButton>
          }
        ></VerticalCard>
      </ContentContainer>
    </Page>
  )
}
