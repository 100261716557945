import { FloatingPortal } from '@floating-ui/react'
import { IAccountingSuggestion } from '@query/suggestions'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { SuggestionCard } from './SuggestionCard'

interface SuggestionCardStackProps {
  suggestions: IAccountingSuggestion[]
  onAccept: (suggestionId: number, modifiedData?: Partial<IAccountingSuggestion>) => Promise<any>
}

export const SuggestionCardStack: React.FC<SuggestionCardStackProps> = ({
  suggestions,
  onAccept
}) => {
  const [showAlternatives, setShowAlternatives] = useState(false)
  const [selectedIndex] = useState(0)
  const suggestion = useMemo(() => {
    return suggestions[selectedIndex]
  }, [selectedIndex])

  return (
    <>
      <StyledCardStack>
        {/*hasAlternatives && (
          <AlternativesBadge onClick={() => setShowAlternatives(true)}>
            {'Katso muita luonnoksia'}
          </AlternativesBadge>
        )*/}
        <AnimatePresence mode="wait">
          <motion.div
            key={`suggestion-${suggestions[selectedIndex].id}`}
            initial={{ x: -10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 10, opacity: 0 }}
            style={{ width: '100%' }}
          >
            <SuggestionCard suggestion={suggestion} onAccept={onAccept} />
          </motion.div>
        </AnimatePresence>
      </StyledCardStack>

      <FloatingPortal>
        <AnimatePresence>
          {showAlternatives && (
            <Carousel onClick={() => setShowAlternatives(false)}>
              {suggestions.map(suggestion => (
                <CarouselCardWrapper key={`suggestion-preview-${suggestion.id}`}>
                  <SuggestionCard suggestion={suggestion} onAccept={async () => null} />
                </CarouselCardWrapper>
              ))}
            </Carousel>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}

const StyledCardStack = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

/*const AlternativesBadge = styled(motion.div).attrs({
  whileHover: { scale: 1.04 },
  whileTap: { scale: 0.96 }
})`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.metalGray};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  padding: 2px 6px;
`*/

const Carousel = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.lg}rem;
  background: ${({ theme }) => theme.colors.backdrop};
  overflow-x: scroll;
`

const CarouselCardWrapper = styled(motion.div)`
  max-width: 400px;
  min-width: 360px;
  width: 100%;
`
