import { setI18nLanguage } from '@localisation/i18next.ts'
import { useEffect } from 'react'
import { ICurrentUser } from '@query'
import { setDayJsLocale } from '@localisation/time.ts'

export enum ValidLocale {
  FI = 'fi',
  EN = 'en'
}

const USER_LOCALE_KEY = 'USER_LOCALE'
const DEFAULT_LOCALE = ValidLocale.FI

const getBrowserLanguage = (): ValidLocale => {
  const lang = navigator?.language?.split('-')[0]?.toUpperCase() as keyof typeof ValidLocale

  return ValidLocale[lang] || DEFAULT_LOCALE
}

export const getCurrentLocale = (): ValidLocale => {
  if (window.localStorage) {
    const persistedLocale = window.localStorage.getItem(USER_LOCALE_KEY) as ValidLocale
    if (persistedLocale) return persistedLocale
  }
  return getBrowserLanguage()
}

export const changeLanguage = (newLocale: ValidLocale): void => {
  if (window.localStorage) {
    window.localStorage.setItem(USER_LOCALE_KEY, newLocale)
  }

  document.documentElement.lang = newLocale

  setI18nLanguage(newLocale)
  setDayJsLocale(newLocale)
}

export const useCurrentUserLanguage = (user?: ICurrentUser): void =>
  useEffect(() => {
    if (user?.language) changeLanguage(user.language)
  }, [user?.language])
