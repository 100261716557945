import nocfoImg from '@assets/nocfo-black.svg'
import { useScreen } from '@utils'
import cn from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  imageUrl?: string
  title?: string
  children: React.ReactNode
}

export const BasicPage: React.FC<Props> = ({ imageUrl, title, children }) => {
  const { isMobile } = useScreen()

  if (isMobile) {
    return (
      <StyledMobilePage>
        {title ? <MainTitle>{title}</MainTitle> : <NoCFOLogo />}
        {imageUrl ? (
          <ImageWrapper className="mobile">
            <Image imageUrl={imageUrl} />
          </ImageWrapper>
        ) : (
          <div style={{ flex: 1 }} />
        )}
        <div>{children}</div>
      </StyledMobilePage>
    )
  }

  return (
    <StyledBasicPage className={cn({ isMobile })}>
      {title ? <MainTitle>{title}</MainTitle> : <NoCFOLogo />}
      <StyledPageBox className={cn({ isMobile, hasImage: !!imageUrl })}>
        {imageUrl && (
          <Column>
            <ImageWrapper>
              <Image imageUrl={imageUrl} />
            </ImageWrapper>
          </Column>
        )}
        <Column>{children}</Column>
      </StyledPageBox>
    </StyledBasicPage>
  )
}

const StyledMobilePage = styled.div`
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl}rem;
  overflow: auto;
`

const StyledBasicPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainBg};
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.neutralBlack};
  overflow: auto;
`

const StyledPageBox = styled(motion.div).attrs({
  initial: { opacity: 0, y: 50, scale: 1.2 },
  animate: { opacity: 1, y: 0, scale: 1 }
})`
  background: white;
  border-radius: 1rem;
  overflow: auto;
  align-self: center;
  box-shadow: 0 2px 13px rgba(27, 31, 35, 0.15);
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing.xxl}rem;
  max-width: 700px;

  &:not(.hasImage) {
    max-width: 400px;
  }
`

const Column = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xl}rem;
  min-width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.mobile {
  }
`

const Image = styled(motion.div).attrs({
  initial: { rotate: 10 },
  animate: { rotate: 0 }
})<{ imageUrl: string }>`
  height: 100%;
  min-height: 150px;
  max-height: 300px;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const NoCFOLogo = styled.div`
  display: flex;
  align-self: center;
  top: 0;
  left: 0;
  width: 80vw;
  min-width: 140px;
  max-width: 200px;
  min-height: 50px;
  background-image: url(${nocfoImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: ${({ theme }) => `${theme.spacing.md}rem ${theme.spacing.xxl}rem`};
`

const MainTitle = styled.h1`
  margin: ${({ theme }) => theme.spacing.md}rem auto;
  text-align: center;
`
