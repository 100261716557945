import { AccountSelector, ActionBar, Button, ContactSelector, UploadFilesButton } from '@components'
import { getSettingsPageUrl, NocfoPlan, UserRole } from '@constants'
import {
  useBusinessContext,
  useDateRangeContext,
  useFeature,
  usePermissionBoundary
} from '@context'
import { fetchBankIntegrations, IDocument, useCurrentUser } from '@query'
import { formatDate } from '@utils'
import dayjs from 'dayjs'
import React, { useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { FaFileUpload, FaPlus } from 'react-icons/fa'
import styled from 'styled-components'
import { DocumentFormModal } from './DocumentForm'
import { IFilter, IFilterButton } from '@root/components/ActionBar/FilterBar/FilterSelection'
import { AdvertisementLink } from '@root/components/ActionBar/ActionBar'
import { useQuery } from 'react-query'

interface Props {
  documents: IDocument[]
  onSearch: (value: string) => void
  onFilter: (filters: any) => void
  uploadFile: (file: File) => Promise<any>
  filters: IFilter[]
}

export const DocumentActionBar: React.FC<Props> = ({
  documents,
  onSearch,
  onFilter,
  uploadFile,
  filters
}) => {
  const [t] = useTranslation()
  const user = useCurrentUser()
  const buttonRef = useRef(null)
  const [showCreate, setShowCreate] = useState(false)
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const hasCsvImport = useFeature('feature_csv_import')
  const { isActive, selectedRange, reset: resetDateRange } = useDateRangeContext()
  const selectedRangeLabel = useMemo(() => {
    return selectedRange?.key === 'custom'
      ? `${formatDate(selectedRange?.date_from)} - ${formatDate(selectedRange?.date_to)}`
      : t('components.dateRangeFilter.' + selectedRange?.key)
  }, [t, selectedRange])
  const { businessId, data: business } = useBusinessContext()

  const { data: bankIntegrations, isLoading } = useQuery([businessId, 'bank_integrations'], () =>
    fetchBankIntegrations({ businessId })
  )

  const isAdvertisementLinkEnabled =
    business?.subscription_plan === NocfoPlan.FREE &&
    bankIntegrations?.results?.length === 0 &&
    !isLoading

  useHotkeys('n', e => {
    buttonRef.current?.click()
    e.preventDefault()
  })

  const initialDate = documents[0]?.date || dayjs().format('YYYY-MM-DD')

  const filterButtons = React.useMemo<IFilterButton[]>(
    () => [
      {
        id: 'is_sales',
        name: t('document.filter.drafts'),
        filter: { is_draft: true }
      },
      {
        id: 'is_flagged',
        name: t('document.filter.flagged'),
        filter: { is_flagged: true }
      },
      {
        id: 'missing_attachment',
        name: t('document.filter.missingAttachment'),
        filter: { has_attachments: false }
      },
      {
        id: 'tagged',
        name: t('document.filter.tagged'),
        filter: { tagged: user?.data?.id }
      }
    ],
    []
  )

  const filterInputs = React.useMemo(
    () => [
      <AccountSelector
        value={filters.filter(value => 'account' in value).map(value => value.account)[0]}
        ref={null}
        clearSelectedItem={() => onFilter(filters.filter(filter => !('account' in filter)))}
        required={false}
        accountFilter={{}}
        label={t('document.filter.account.label')}
        info={t('document.filter.account.info')}
        placeholder={t('document.filter.account.placeholder')}
        queryKey={'accounts'}
        id={'account_search'}
        key={'account_search'}
        onChange={accountId => {
          let exists = false

          const tempFilters = filters.map(filter => {
            if ('account' in filter) {
              exists = true
              return { ...filter, account: accountId }
            }
            return filter
          })

          if (!exists || filters.length === 0) {
            tempFilters.push({ account: accountId })
          }

          onFilter(tempFilters)
        }}
      />,
      <ContactSelector
        value={filters.filter(value => 'contact' in value).map(value => value.contact)[0]}
        ref={null}
        clearSelectedItem={() => onFilter(filters.filter(filter => !('contact' in filter)))}
        required={false}
        accountFilter={{}}
        info={t('document.filter.contact.info')}
        label={t('document.filter.contact.label')}
        placeholder={t('document.filter.contact.placeholder')}
        noDataMessage={t('document.filter.contact.noData')}
        queryKey={'contacts'}
        id={'contact_search'}
        key={'contact_search'}
        onChange={contactId => {
          let exists = false

          const tempFilters = filters.map(filter => {
            if ('contact' in filter) {
              exists = true
              return { ...filter, contact: contactId }
            }
            return filter
          })

          if (!exists || filters.length === 0) {
            tempFilters.push({ contact: contactId })
          }

          onFilter(tempFilters)
        }}
        fetchOnlyInvoicableContacts={false}
      />
    ],
    [filters]
  )

  const primaryButtons = [
    <Button
      key="add-btn"
      ref={buttonRef}
      icon={<FaPlus />}
      onClick={() => setShowCreate(true)}
      data-test={'new-document-button'}
    >
      {t('document.addNew')}
    </Button>,
    ...(hasCsvImport
      ? [
          <UploadFilesButton
            key="csv-import-btn"
            icon={<FaFileUpload />}
            fileTypes={['.csv']}
            uploadFile={uploadFile}
          >
            {t('document.csvImport')}
          </UploadFilesButton>
        ]
      : [])
  ]

  return (
    <>
      <ActionBar
        searchPlaceholder={t('document.searchPlaceholder')}
        onSearch={search => onSearch(search)}
        onFilter={filters => onFilter(filters)}
        filterButtons={filterButtons}
        filterInputs={filterInputs}
        activeFilters={filters}
        primaryButtons={isEditor ? primaryButtons : []}
        isLast={!isActive}
        additionalContent={
          isAdvertisementLinkEnabled && [
            <AdvertisementLink
              key="advertisement-link"
              text={t('document.advertisingLink')}
              link={getSettingsPageUrl(businessId, { page: 'integrations' })}
            />
          ]
        }
      />
      {isActive && (
        <FilterInfo>
          <span className="label">
            {t('document.documentsShown', { timePeriod: selectedRangeLabel })}
          </span>
          <a href="#" onClick={() => resetDateRange()} style={{ fontWeight: 'normal' }}>
            {t('document.showAll')}
          </a>
        </FilterInfo>
      )}

      <DocumentFormModal
        isVisible={showCreate}
        onClose={() => setShowCreate(false)}
        defaults={{ date: initialDate }}
        enableCache={true}
      />
    </>
  )
}

const FilterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.neutralWhite}77;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: 1rem;

  .label {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xxs}rem;

    svg {
      margin-top: -2px;
    }
  }
`
