import Cookies from 'js-cookie'

const AUTH_TOKEN_NAME = 'nocfo-auth-token'
const AUTH_TOKEN_EXPIRATION_DAYS = 28

export const setAuthenticationToken = (token: string): void => {
  Cookies.set(AUTH_TOKEN_NAME, token, {
    expires: AUTH_TOKEN_EXPIRATION_DAYS
  })
}

export const clearAuthenticationToken = (): void => {
  Cookies.remove(AUTH_TOKEN_NAME)
}

const parseURLHash = (hash: string): Record<string, string> =>
  hash
    .replace('#', '')
    .split('&')
    .map(str => str.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

export const getAuthenticationToken = (): string => {
  const params = parseURLHash(window.location.hash)
  if (params.token) return params.token
  return Cookies.get(AUTH_TOKEN_NAME)
}

export const isAuthenticated = (): boolean => !!getAuthenticationToken()
