import { NocfoPlan, NocfoPlanSource, VatRateLabel } from '@constants'
import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'

export interface IVatRateOption {
  label: VatRateLabel
  rate: number
}

export interface IVatConfig {
  effective_from: string
  default_vat_rate_label: string
  vat_rate_options: IVatRateOption[]
}

interface ICountryFeature {
  enable_banking: boolean
  salaxy: boolean
  verohallinto: boolean
}

export interface ICountryConfig {
  vat_configs: IVatConfig[]
  feature: ICountryFeature
}

export enum BusinessForms {
  FI_OY = 'FI_OY',
  FI_OYJ = 'FI_OYJ',
  FI_TMI = 'FI_TMI',
  FI_KY = 'FI_KY',
  FI_OK = 'FI_OK',
  FI_AY = 'FI_AY',
  FI_AS_OY = 'FI_AS_OY',
  FI_KOY = 'FI_KOY',
  FI_NY = 'FI_NY',
  FI_YHD = 'FI_YHD',
  DEMO = 'DEMO'
}

export interface IBusiness {
  id: number
  logo: string
  name: string
  business_id: string
  country_config: ICountryConfig
  default_vat_period: number
  invoicing_default_penalty_interest: number
  invoicing_default_payment_condition_days: number
  form: BusinessForms
  has_history_before_nocfo: boolean
  owner_email: string
  owner_name: string
  period_id: number
  created_at: string
  vat_period_id: number
  coa_size: number
  contact_phone: string

  // Mailing address
  has_business_address: boolean
  business_street?: string
  business_city?: string
  business_postal_code?: string
  business_country?: string

  // Invoicing info
  invoicing_email?: string
  invoicing_iban?: string
  invoicing_tax_code?: string
  invoicing_bic?: string
  invoicing_contact?: string
  invoicing_street?: string
  invoicing_city?: string
  invoicing_postal_code?: string
  invoicing_country?: string
  can_invoice: boolean

  // Onboarding stuff
  onboarding_updated_invoicing_settings: boolean
  onboarding_created_invoicing_contact: boolean
  onboarding_created_invoicing_product: boolean
  onboarding_einvoice_enabled: boolean

  // e-invoicing
  einvoicing_enabled: boolean
  einvoicing_address?: string
  einvoicing_operator?: string
  is_eligible_for_einvoicing: boolean

  // Salaxy
  salaxy_enabled: boolean
  salaxy_verified: boolean
  salaxy_account_id?: string

  // Holvi
  holvi_bookkeeping_api_enabled: boolean

  // Kravia
  kravia_enabled: boolean
  kravia_customer_id?: number

  // Trialing
  is_trialing: boolean
  trial_progress_percentage: number
  trial_days_left: number

  // Stripe
  stripe_customer_id: string
  stripe_subscription_id: string

  // Subscription plan
  subscription_plan: NocfoPlan
  subscription_source: NocfoPlanSource

  // Default accounts
  account_yle_tax_id: number
  account_tax_deferrals_id: number
  account_income_tax_rec_id: number
  account_income_tax_lia_id: number
  account_previous_profit_id: number
  account_vat_liabilities_id: number

  // Demo
  demo_days_left: number

  // Verohallinto
  verohallinto_latest_reporter_full_name: string
  verohallinto_latest_reporter_phone_number: string
}

export const fetchBusinesses: QueryFunction<void, PaginatedResponse<IBusiness>> = async (
  _,
  params = null
) => {
  const url = `/v1/business/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface FetchBusinessProps {
  businessId: string
}

export const fetchBusiness: QueryFunction<FetchBusinessProps, IBusiness> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface UpdateBusinessProps {
  businessId: string
}

export const updateBusiness: MutationFunction<UpdateBusinessProps, IBusiness> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface CreateBusinessProps {
  coaSize: number
}

export const createBusiness: MutationFunction<CreateBusinessProps, IBusiness> = async (
  { coaSize },
  requestData
) => {
  const url = `/v1/business/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    params: { coa_size: coaSize },
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteBusiness: MutationFunction<UpdateBusinessProps, void> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

export interface CreateNYBusinessProps {
  name: string
}

export const createNYBusiness: MutationFunction<void, CreateNYBusinessProps> = async (
  _,
  requestData
) => {
  const url = '/v1/business/ny-onboard/'
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export interface ICreateDemoBusinessProps {
  useMockData: boolean
}

export const createDemoBusiness: MutationFunction<
  ICreateDemoBusinessProps,
  CreateNYBusinessProps
> = async ({ useMockData }) => {
  const url = '/v1/business/demo-onboard/'
  const { status, data } = await network.httpClient.request({
    url,
    data: { use_mock_data: useMockData },
    method: 'POST',
    validateStatus: () => true,
    timeout: 30000
  })
  return handleReturn({ status, data })
}

interface UploadLogoParams {
  businessId: string
}

export const uploadLogo: MutationFunction<UploadLogoParams, File> = async (
  { businessId },
  file
) => {
  const url = `/v1/business/${businessId}/logo/filename/`
  const { data } = await network.httpClient.put(url, file, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const deleteLogo: MutationFunction<UploadLogoParams, void> = async ({ businessId }) => {
  const url = `/v1/business/${businessId}/logo/`
  await network.httpClient.request({ url, method: 'DELETE' })
}

export const enableEinvoicing: MutationFunction<FetchBusinessProps, void> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/einvoicing/enable/`
  await network.httpClient.request({
    url,
    method: 'POST',
    data: null
  })
}

export const disableEinvoicing: MutationFunction<FetchBusinessProps, void> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/einvoicing/disable/`
  await network.httpClient.request({
    url,
    method: 'POST',
    data: null
  })
}
