interface QueryParams {
  [key: string]: any
}

const omitEmptyValues = <T>(obj: T): Partial<T> => {
  for (const propName in obj) {
    if (!obj[propName]) {
      delete obj[propName]
    }
  }
  return obj
}

const appendParams = (url: string, params?: QueryParams): string => {
  const params_ = omitEmptyValues(params || {}) as Record<string, string>
  const search = new URLSearchParams(params_).toString()
  const fullUrl = `${url}?${search}`

  return fullUrl.endsWith('?') ? fullUrl.slice(0, -1) : fullUrl
}

/*
 * Dashboard page
 */

export const getDashboardPageUrl = (businessId: string): string =>
  appendParams(`/${businessId}/`, {})

/*
 * Bank integration flow page
 */
export const getBankIntegrationPageURL = (
  businessId: string,
  page: 'aspsps' | 'holvi-edit' | 'holvi-finalize'
): string => appendParams(`/${businessId}/bank-integration-flow/${page}`, {})

/*
 * Documents page
 */

export interface IDocumentsPageURLParams {
  id?: number | null
  tab?: 'overview' | 'import_data' | 'suggestions' | 'audit_trail'
  extra?: 'ai-help' | 'import_data'
}

export const getDocumentsPageUrl = (businessId: string, params?: IDocumentsPageURLParams): string =>
  appendParams(`/${businessId}/documents`, params)

/*
 * Files page
 */

interface IFilesPageURLParams {
  id: number | null
  idType: 'file' | 'folder' | null
  folder?: number
}

export const getFilesPageUrl = (businessId: string, params?: IFilesPageURLParams): string =>
  appendParams(`/${businessId}/files`, params)

/*
 * Settings page
 */

interface ISettingsPageURLParams {
  page:
    | 'settings'
    | 'accounts'
    | 'people'
    | 'integrations'
    | 'plan'
    | 'invoicing'
    | 'holvi'
    | 'einvoicing'
}

export const getSettingsPageUrl = (businessId: string, params?: ISettingsPageURLParams): string =>
  appendParams(`/${businessId}/settings`, params)

/*
 * Period page
 */

interface IPeriodPageURLParams {
  id: number
  tab?: 'details' | 'financial-statement'
}

export const getPeriodPageUrl = (businessId: string, params?: IPeriodPageURLParams): string =>
  appendParams(`/${businessId}/accounting`, params)

/*
 * VAT page
 */

interface IVatPageURLParams {
  id: number
}

export const getVATPageUrl = (businessId: string, params?: IVatPageURLParams): string =>
  appendParams(`/${businessId}/vat`, params)

/*
 * Product page
 */

interface IProductPageURLParams {
  id: number
}

export const getProductPageUrl = (businessId: string, params?: IProductPageURLParams): string =>
  appendParams(`/${businessId}/products`, params)

/*
 * Invoicing page
 */

interface IInvoicingPageSalesOpenParams {
  id: number
  type: 'sales'
}
interface IInvoicingPagePurchaseOpenParams {
  id: number
  type: 'purchase'
}

type IInvoicingPageURLParams = IInvoicingPageSalesOpenParams | IInvoicingPagePurchaseOpenParams

export const getInvoicingPageUrl = (businessId: string, params?: IInvoicingPageURLParams): string =>
  appendParams(`/${businessId}/invoicing`, params)

/*
 * Contact page
 */

interface IContactPageURLParams {
  id: number
}

export const getContactPageUrl = (businessId: string, params?: IContactPageURLParams): string =>
  appendParams(`/${businessId}/contacts`, params)
