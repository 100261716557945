import { Alert } from '@root/components'

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  hasAuthorization: boolean
}

const VatAuhtorization: React.FC<Props> = ({ hasAuthorization }) => {
  const [t] = useTranslation()

  const text = hasAuthorization ? t('vatPage.authorization.ok') : t('vatPage.authorization.invalid')
  const description = hasAuthorization ? (
    t('vatPage.authorization.infoOk')
  ) : (
    <Trans
      i18nKey="vatPage.authorization.infoInvalid"
      components={[
        <a key="1" target="_blank" rel="noreferrer" href={t('link.docs.suomiauthorization')} />
      ]}
    />
  )

  return (
    <Alert type={hasAuthorization ? 'success' : 'error'} title={text} description={description} />
  )
}

export default VatAuhtorization
