import { ActionBar, Button, TextTooltip } from '@components'
import { AccountType, UserRole } from '@constants'
import { PermissionBoundary, usePermissionBoundary } from '@context'
import { useQueryParam } from '@hooks'
import { useScreen } from '@utils'
import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBars, FaPlus, FaTable } from 'react-icons/fa'
import { AccountFormModal } from './AccountFormModal'
import { IFilter, IFilterButton } from '@root/components/ActionBar/FilterBar/FilterSelection'

interface Props {
  onSearch: (value: string) => void
  onFilter: (value: IFilter[]) => void
  activeFilters: Array<Record<any, any>>
}

export const AccountActionBar: React.FC<Props> = ({ onSearch, onFilter, activeFilters }) => {
  const [t] = useTranslation()
  const [editMode, setEditMode] = useQueryParam<string>('editMode')
  const [isFormVisible, setFormVisible] = useState(false)
  const { isMobile } = useScreen()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)

  const filterButtons = React.useMemo<IFilterButton[]>(
    () => [
      {
        id: 'is_used',
        name: t('accounts.actionBar.filterIsUsed'),
        filter: { is_used: true }
      },
      {
        id: 'bank',
        name: t('accounts.actionBar.filterBank'),
        filter: { type: [AccountType.ASS_PAY] }
      },
      {
        id: 'revenue',
        name: t('accounts.actionBar.filterRev'),
        filter: { type: [AccountType.REV, AccountType.REV_SAL, AccountType.REV_NO] }
      },
      {
        id: 'expense',
        name: t('accounts.actionBar.filterExp'),
        filter: { type: [AccountType.EXP, AccountType.EXP_NO, AccountType.EXP_50] }
      },
      {
        id: 'taxes',
        name: t('accounts.actionBar.filterTax'),
        filter: {
          type: [AccountType.EXP_TAX, AccountType.LIA_VAT, AccountType.ASS_VAT]
        }
      }
    ],
    []
  )

  const secondaryActions = [
    ...(!isMobile
      ? [
          <PermissionBoundary requireRole={UserRole.EDITOR} key="toggle-secondary-action">
            <TextTooltip tooltip={t('accounts.actionBar.editModeToggleTooltip')}>
              <AnimatePresence>
                {editMode === 'tabular' && (
                  <Button
                    isSecondary={true}
                    icon={<FaBars />}
                    onClick={() => setEditMode(undefined)}
                  >
                    {t('accounts.actionBar.showSimple')}
                  </Button>
                )}
                {editMode !== 'tabular' && (
                  <Button
                    isSecondary={true}
                    icon={<FaTable />}
                    size="sm"
                    onClick={() => setEditMode('tabular')}
                  >
                    {t('accounts.actionBar.showTabular')}
                  </Button>
                )}
              </AnimatePresence>
            </TextTooltip>
          </PermissionBoundary>
        ]
      : [])
  ]

  const primaryButtons = [
    <Button key="account-main-action" onClick={() => setFormVisible(true)} icon={<FaPlus />}>
      {t('accounts.actionBar.newAccount')}
    </Button>
  ]

  return (
    <>
      <ActionBar
        searchPlaceholder={t('accounts.actionBar.search')}
        onSearch={search => onSearch(search)}
        onFilter={filters => onFilter(filters)}
        filterButtons={filterButtons}
        activeFilters={activeFilters}
        primaryButtons={isEditor ? primaryButtons : []}
        additionalContent={secondaryActions}
      />
      <AccountFormModal isVisible={isFormVisible} handleOnClose={() => setFormVisible(false)} />
    </>
  )
}
