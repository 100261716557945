import { AnimatedContentLoader, Button, GoalStepper } from '@components'
import { getContactPageUrl, getProductPageUrl, getSettingsPageUrl } from '@constants'
import { FeatureWrapper, useBusinessContext } from '@context'
import { fetchBusiness, updateBusiness } from '@query'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaForward } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const InvoicingOnboarding: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const { data, isLoading, refetch } = useQuery([businessId], () => fetchBusiness({ businessId }), {
    refetchOnMount: true
  })

  const showEInvoicingStep = data?.is_eligible_for_einvoicing

  const { mutateAsync: skipOnboarding, isLoading: isSkipping } = useMutation(
    () =>
      updateBusiness(
        { businessId },
        {
          onboarding_updated_invoicing_settings: true,
          onboarding_created_invoicing_contact: true,
          onboarding_created_invoicing_product: true,
          onboarding_einvoice_enabled: true
        }
      ),
    {
      onSuccess: () => refetch()
    }
  )

  return (
    <Wrapper>
      <AnimatedContentLoader isLoading={isLoading}>
        <>
          <GoalStepper
            header={t('invoicing.steps.header')}
            goals={[
              ...(showEInvoicingStep
                ? [
                    {
                      content: (
                        <Trans i18nKey="invoicing.steps.activateEInvoicing">
                          <Link to={getSettingsPageUrl(businessId, { page: 'einvoicing' })} />
                        </Trans>
                      ),
                      isCompleted: data?.onboarding_einvoice_enabled
                    }
                  ]
                : []),
              {
                content: (
                  <Trans i18nKey="invoicing.steps.addInvoicingSettings">
                    <Link to={getSettingsPageUrl(businessId, { page: 'invoicing' })} />
                  </Trans>
                ),
                isCompleted: data?.onboarding_updated_invoicing_settings
              },
              {
                content: (
                  <Trans i18nKey="invoicing.steps.addContactAndProduct">
                    <Link to={getContactPageUrl(businessId)} />
                    <Link to={getProductPageUrl(businessId)} />
                  </Trans>
                ),
                isCompleted:
                  data?.onboarding_created_invoicing_contact &&
                  data?.onboarding_created_invoicing_product,
                isUnderway:
                  data?.onboarding_created_invoicing_contact ||
                  data?.onboarding_created_invoicing_product
              }
            ]}
          />

          <FeatureWrapper feature="feature_show_received_einvoices">
            <AdditionalContent>
              <Button
                icon={<FaForward />}
                onClick={() => skipOnboarding()}
                disabled={isSkipping}
                showSpinner={isSkipping}
              >
                {t('invoicing.steps.skipButton')}
              </Button>
            </AdditionalContent>
          </FeatureWrapper>
        </>
      </AnimatedContentLoader>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  border-radius: 1rem;

  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.spacing.sm}rem;
`

const AdditionalContent = styled.div`
  margin: ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
`
