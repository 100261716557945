import React, { cloneElement, JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { ActionBarContainer, Row, AdditionalContent } from './ActionBar.styled'
import { SearchInput } from '../SearchInput'
import { useScreen } from '@root/utils'
import FilterSelection, { IFilterButton } from './FilterBar/FilterSelection'
import cn from 'classnames'
import { ButtonGroup } from '@components/Button/ButtonGroup.tsx'
import { DropdownMenu } from '@components/DropdownMenu'
import { Button } from '@root/components'
import { FaCaretDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { theme } from '@root/styles'

export interface IPrimaryButton {
  render: (isCompact: boolean) => ReactNode
  isMainAction: boolean
}

type ElementType = ReactElement<any, string | JSXElementConstructor<any>>

interface Props {
  // Buttons
  primaryButtons?: ElementType[]
  additionalContent?: ReactNode[]

  // Input / Search
  searchPlaceholder?: string
  onSearch?: (string) => void

  // Filters
  filterButtons?: IFilterButton[]
  activeFilters?: Array<Record<any, any>>
  filterInputs?: ReactNode[]
  onFilter?: (any) => void

  // Styling
  isLast?: boolean
}

export const ActionBar: React.FC<Props> = ({
  searchPlaceholder,
  primaryButtons,
  onSearch,
  filterButtons,
  onFilter,
  additionalContent,
  filterInputs,
  activeFilters,
  isLast = true
}) => {
  const { sm: isCompact } = useScreen()
  const hasFilters = onFilter && activeFilters

  const firstButton = primaryButtons?.[0]
  const otherButtons = primaryButtons?.slice(1) || []

  return (
    <ActionBarContainer className={cn({ isLast: isLast })}>
      <Row>
        {hasFilters && (
          <FilterSelection
            filters={activeFilters}
            filterButtonOptions={filterButtons}
            filterSearchOptions={filterInputs}
            onFilter={onFilter}
          />
        )}

        {onSearch ? (
          <SearchInput
            onSearch={value => {
              onSearch(value)
            }}
            placeholder={searchPlaceholder || ''}
          ></SearchInput>
        ) : (
          <div style={{ flex: 1 }} />
        )}

        {isCompact ? (
          firstButton &&
          (otherButtons.length > 0 ? (
            <DropdownMenu items={[firstButton, ...otherButtons]}>
              <Button intent="primary" icon={<FaCaretDown />} />
            </DropdownMenu>
          ) : (
            cloneElement(firstButton, { intent: 'primary', children: null })
          ))
        ) : (
          <ButtonGroup>
            {firstButton}
            {otherButtons.length > 0 && (
              <DropdownMenu items={otherButtons}>
                <Button icon={<FaCaretDown />} />
              </DropdownMenu>
            )}
          </ButtonGroup>
        )}
      </Row>

      {additionalContent && additionalContent?.length !== 0 && (
        <Row>
          <AdditionalContent>{additionalContent.map(action => action)}</AdditionalContent>
        </Row>
      )}
    </ActionBarContainer>
  )
}

interface IAdvertisementProps {
  text: string
  link: string
}

export const AdvertisementLink: React.FC<IAdvertisementProps> = ({ text, link }) => {
  return (
    <Link to={link} style={{ fontSize: `${theme.fontSize.sm}rem` }}>
      {text}
    </Link>
  )
}
