import microsoftSvg from '@assets/login/microsoft.svg'
import googleSvg from '@assets/login/google.svg'
import nocfoIconSrc from '@assets/nocfo-icon-color.svg'
import { Alert, AnimatedContentLoader, Button } from '@components'
import { useGoogleLogin, useMicrosoftLogin } from '@utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useQueryParam } from '@hooks'

interface Props {
  isRegister?: boolean
}

export const Login: React.FC<Props> = ({ isRegister }) => {
  const [t] = useTranslation()
  const [errorMsg, setErrorMsg] = useState<string>('')

  return (
    <div>
      <Alert
        type="error"
        isVisible={Boolean(errorMsg)}
        title={isRegister ? t('login.registerErrorTitle') : t('login.loginErrorTitle')}
        description={errorMsg}
      />
      <LoginButtons isRegister={isRegister} setErrorMsg={setErrorMsg} />
    </div>
  )
}

interface LoginButtonsProps {
  isRegister?: boolean
  setErrorMsg: (msg: string) => void
}

const LoginButtons: React.FC<LoginButtonsProps> = ({ isRegister, setErrorMsg }) => {
  const [t] = useTranslation()
  const [next] = useQueryParam<string>('next')

  const { login: googleLogin, isLoading: isGoogleLoading } = useGoogleLogin({
    onError: () => setErrorMsg(t('login.generalErrorMessage')),
    next
  })

  const { login: microsoftLogin, isLoading: isMicrosoftLoading } = useMicrosoftLogin({
    onError: () => setErrorMsg(t('login.generalErrorMessage')),
    next
  })

  return (
    <AnimatedContentLoader isLoading={isGoogleLoading || isMicrosoftLoading}>
      <ButtonsWrapper>
        <LoginButton src={googleSvg} onClick={() => googleLogin()}>
          {isRegister ? t('login.registerWithGoogle') : t('login.loginWithGoogle')}
        </LoginButton>
        <LoginButton src={microsoftSvg} onClick={() => microsoftLogin()}>
          {isRegister ? t('login.registerWithMicrosoft') : t('login.loginWithMicrosoft')}
        </LoginButton>
        <Link
          to={{
            pathname: isRegister ? '/nocfo-signup' : 'nocfo-signin',
            search: window.location.search
          }}
        >
          <LoginButton src={nocfoIconSrc}>
            {isRegister ? t('login.registerWithNocfo') : t('login.loginWithNocfo')}
          </LoginButton>
        </Link>
      </ButtonsWrapper>
    </AnimatedContentLoader>
  )
}

interface LoginButtonProps extends React.HTMLProps<HTMLButtonElement> {
  src: string
}

const LoginButton: React.FC<LoginButtonProps> = ({ src, children, onClick, disabled }) => {
  return (
    <StyledLoginButton
      icon={<LoginButtonIcon src={src} />}
      onClick={onClick}
      disabled={disabled}
      className="is-secondary"
    >
      {children}
    </StyledLoginButton>
  )
}

const StyledLoginButton = styled(Button)`
  display: flex;
  background: white;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  color: #3c4043;
  width: 100%;
  overflow: hidden;
`

const LoginButtonIcon = styled.img`
  margin-right: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
