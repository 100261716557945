import { AnimatedContentLoader, Button, ModalV2 } from '@components'
import { useBusinessContext } from '@context'
import { fetchPricingTableKeys } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const PricingTable: React.FC = () => {
  const { businessId } = useBusinessContext()
  const {
    isSuccess: isKeysSuccess,
    isStale,
    data: keys
  } = useQuery([businessId, 'pricing-table-keys'], () => fetchPricingTableKeys({ businessId }), {
    // 20 min. Stripe Pricing Table keeps session 30 min open so this leaves enough buffer for that
    staleTime: 1200000,
    // Refetch every 10 min to keep customer session refreshed
    refetchInterval: 600000,
    // Refetch options to keep customer session fresh
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always'
  })
  const isLoading =
    // Make sure keys are loaded
    !isKeysSuccess ||
    // Double check that stale data is never passed to pricing table
    isStale

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      {isKeysSuccess && (
        <div
          dangerouslySetInnerHTML={{
            __html: `
          <stripe-pricing-table
            pricing-table-id="${keys?.pricing_table_id}"
            publishable-key="${keys?.pricing_table_publishable_key}"
            customer-session-client-secret="${keys?.pricing_table_customer_session_client_secret}"
            client-reference-id="${businessId}"
          ></stripe-pricing-table>`
          }}
        />
      )}
    </AnimatedContentLoader>
  )
}

export const PricingTableModal: React.FC<{ isVisible: boolean; handleOnClose: () => void }> = ({
  isVisible,
  handleOnClose
}) => {
  const [t] = useTranslation()
  return (
    <ModalV2
      header={t('components.pricingTable.subscribe')}
      isVisible={isVisible}
      handleOnClose={() => handleOnClose()}
      width={1200}
      height={1000}
      footer={
        <StyledFooter>
          <Button onClick={() => handleOnClose()}>{t('general.close')}</Button>
        </StyledFooter>
      }
    >
      <PricingTable />
    </ModalV2>
  )
}

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`
