import { UserRole } from '@constants'
import { ValidLocale } from '@localisation'
import { network } from '@utils'
import { isAuthenticated } from '@utils/tokenUtils'
import { useQuery, UseQueryResult } from 'react-query'
import { INotification } from './notifications'
import { handleReturn, MutationFunction, QueryFunction } from './root'

export interface IBusinessUsership {
  business: number
  business_id: string
  role: UserRole
}

export type SubscriptionTier = 'FREE' | 'PAID' | 'NONE'

export interface ICurrentUser {
  id: number
  email: string
  first_name: string
  last_name: string
  avatar_url: string
  invitations: []
  is_active: boolean
  last_login: string
  date_joined: string
  notifications: INotification[]
  tos_accepted: boolean
  last_csat: string
  userships: IBusinessUsership[]
  show_get_started_info: boolean
  language: ValidLocale
  last_active_business_id?: string
  subscription_tier: SubscriptionTier
}

export const useCurrentUser = (): UseQueryResult<ICurrentUser> =>
  useQuery('user', () => fetchCurrentUser(), {
    retry: false,
    enabled: isAuthenticated(),
    refetchOnMount: false,
    onError: ({ status }) => {
      if (status === 401) {
        console.log('User unauthenticated, clearing authentication token ...')
        network.clearAccessToken()
      }
    }
  })

export const useIsNocfoStaff = (): boolean => {
  const { data: user } = useCurrentUser()
  return user?.email?.endsWith('@nocfo.io')
}

export const fetchCurrentUser: QueryFunction<void, ICurrentUser> = async () => {
  const url = `/v1/user/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'GET',
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export const updateCurrentUser: MutationFunction<void, ICurrentUser> = async (_, requestData) => {
  const url = `/v1/user/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export const logOut: MutationFunction<void, void> = async () => {
  const url = '/auth/dj-rest-auth/logout/'
  await network.httpClient.request({
    url,
    method: 'POST'
  })
  network.clearAccessToken()
}

interface InvitationInstanceProps {
  invitationId: number
}

export const acceptInvitation: MutationFunction<InvitationInstanceProps, void> = async ({
  invitationId
}) => {
  const url = `/v1/user/invitation/${invitationId}/`
  await network.httpClient.request({
    url,
    method: 'PATCH',
    data: { status: 'ACCEPTED' }
  })
}

export const rejectInvitation: MutationFunction<InvitationInstanceProps, void> = async ({
  invitationId
}) => {
  const url = `/v1/user/invitation/${invitationId}/`
  await network.httpClient.request({
    url,
    method: 'PATCH',
    data: { status: 'REJECTED' }
  })
}

export const acceptTos: MutationFunction<void, void> = async () => {
  const url = '/v1/user/accept_tos/'
  await network.httpClient.request({
    url,
    method: 'POST'
  })
}

export const toggleGetStartedInfo: MutationFunction<
  Pick<ICurrentUser, 'show_get_started_info'>,
  void
> = async ({ show_get_started_info }) => {
  const url = '/v1/user/'
  await network.httpClient.request({
    url,
    method: 'PATCH',
    data: { show_get_started_info }
  })
}
