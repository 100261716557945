import {
  Avatar,
  HelpCenter,
  PopupMenu,
  PopupMenuButton,
  PopupMenuContainer,
  PopupMenuItem
} from '@components'
import { LanguagePicker } from '@containers/LanguagePicker'
import { NotificationFeedPopup } from '@containers/NotificationFeed'
import { useDebugMode, useQueryParam } from '@hooks'
import { logOut, useCurrentUser, useIsNocfoStaff } from '@query'
import { isAuthenticated } from '@utils/tokenUtils'
import { Heading } from 'evergreen-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaBars,
  FaBug,
  FaHome,
  FaLifeRing,
  FaPenNib,
  FaQuestionCircle,
  FaSignOutAlt
} from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import useScreen from '../../utils/useScreen'
import {
  AIHelpBg,
  AIHelpButton,
  HamburgerButton,
  HeaderSpacer,
  RightMenuWrapper,
  StyledHeader
} from './Header.styled'
import cn from 'classnames'
import { HiSparkles } from 'react-icons/hi2'
import { useAIHelpContext } from '@context'

interface HeaderProps {
  label: string | React.ReactNode
  labelStyle?: Record<string, any>
}

export const Header: React.FC<HeaderProps> = ({ label, labelStyle }) => {
  const [t] = useTranslation()
  const [, setMenuOpen] = useQueryParam<string>('menu')
  const { isInitialized, isAIHelpOpen, setAIHelpOpen } = useAIHelpContext()

  const { isMobile } = useScreen()

  return (
    <StyledHeader className={cn({ isMobile })}>
      {isMobile && (
        <HamburgerButton onClick={() => setMenuOpen('true')}>
          <FaBars />
        </HamburgerButton>
      )}

      <Heading alignSelf="center" style={labelStyle}>
        {label}
      </Heading>

      <HeaderSpacer />

      {/* ACTIONS */}
      {isAuthenticated() && (
        <RightMenuWrapper>
          {isInitialized && (
            <AIHelpButton onClick={() => setAIHelpOpen(!isAIHelpOpen)} className={cn({ isMobile })}>
              <AIHelpBg />
              <HiSparkles />
              <span>{t('aiHelp.header.title')}</span>
            </AIHelpButton>
          )}

          <NotificationFeedPopup />
          <AvatarMenu />
        </RightMenuWrapper>
      )}
    </StyledHeader>
  )
}

const AvatarMenu: React.FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [debugMode, setDebugMode] = useDebugMode()

  const { data: user } = useCurrentUser()
  const isNocfoStaff = useIsNocfoStaff()

  const logOutMutation = useMutation(() => logOut(), {
    onSettled: async () => {
      queryClient.clear()
      await queryClient.invalidateQueries()
      window.location.assign('/')
    }
  })

  return (
    <PopupMenuContainer>
      <PopupMenu>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <PopupMenuItem icon={<FaHome />} title={t('userMenu.linkToFrontpage')} />
        </Link>
        <a
          href={t('link.docs.main')}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <PopupMenuItem icon={<FaQuestionCircle />} title={t('link.docsTitle')} />
        </a>
        <a
          href={t('link.feedback')}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <PopupMenuItem icon={<FaPenNib />} title={t('link.feedbackTitle')} />
        </a>

        <HelpCenter>
          <PopupMenuItem icon={<FaLifeRing />} title={t('userMenu.help')} />
        </HelpCenter>

        {isNocfoStaff && (
          <PopupMenuItem
            icon={<FaBug />}
            onClick={() => setDebugMode(!debugMode)}
            title={t('userMenu.debug')}
          />
        )}

        <LanguagePicker />

        <hr />

        <PopupMenuItem
          icon={<FaSignOutAlt />}
          onClick={() => logOutMutation.mutate()}
          title={t('userMenu.logOut')}
        />
      </PopupMenu>

      <PopupMenuButton>
        <div style={{ cursor: 'pointer' }}>
          <Avatar src={user?.avatar_url} role="button" size={32} />
        </div>
      </PopupMenuButton>
    </PopupMenuContainer>
  )
}
