import { useBusinessContext } from '@context'
import { useCallback, useReducer, useState } from 'react'
import { fetchFiles, IBusiness, IFile } from '@query'
import axios from 'axios'
import dayjs from 'dayjs'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

const getZipFileName = (business: Partial<IBusiness>): string =>
  `${business.business_id} ${business.name}`

const getFileExtension = (path: string): string => {
  const [head] = path.split('?')
  const parts = head.split('.')
  return parts.length > 1 ? parts.pop() ?? '' : ''
}

const sanitizeFileName = (name: string): string =>
  name
    // eslint-disable-next-line no-control-regex
    .replace(/[<>:"/\\|?*\x00-\x1F]/g, '')
    .replace(/\s+/g, '_')
    .trim()

const getFileName = (file: IFile): string => {
  const sanitizedName = sanitizeFileName(file.name)
  const fileName = sanitizedName.includes('.')
    ? sanitizedName
    : `${sanitizedName}.${getFileExtension(file.file)}`
  const createdAt = dayjs(file.created_at)
  return `${createdAt.format('YYYY/MM/DD')}/${fileName}`
}

interface DownloadState {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  totalFiles: number
  filesDownloaded: number
}

type Action =
  | { type: 'START' }
  | { type: 'SET_TOTAL_FILES'; payload: number }
  | { type: 'INCREMENT_FILES_DOWNLOADED' }
  | { type: 'SUCCESS' }
  | { type: 'ERROR' }
  | { type: 'RESET' }

const initialState: DownloadState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  totalFiles: Infinity,
  filesDownloaded: 0
}

function reducer(state: DownloadState, action: Action): DownloadState {
  switch (action.type) {
    case 'START':
      return { ...initialState, isLoading: true }
    case 'SET_TOTAL_FILES':
      return { ...state, totalFiles: action.payload }
    case 'INCREMENT_FILES_DOWNLOADED':
      return { ...state, filesDownloaded: state.filesDownloaded + 1 }
    case 'SUCCESS':
      return { ...state, isLoading: false, isSuccess: true }
    case 'ERROR':
      return { ...state, isLoading: false, isError: true }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

interface PropsIn {
  onSuccess?: () => void
  onError?: () => void
}

interface PropsOut {
  state: DownloadState
  progress: number
  startZipGeneration: () => Promise<void>
  downloadZip: () => void
  reset: () => void
}

export const useDownloadFilesAsZip = ({ onSuccess, onError }: PropsIn): PropsOut => {
  const { businessId, data: business } = useBusinessContext()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [blob, setBlob] = useState<Blob>(null)
  const progress = Math.ceil((state.filesDownloaded / state.totalFiles) * 100)

  const _onSuccess = () => {
    dispatch({ type: 'SUCCESS' })
    onSuccess?.()
  }

  const _onError = () => {
    dispatch({ type: 'ERROR' })
    onError?.()
  }

  const _saveAs = (blobResult: Blob) => {
    saveAs(blobResult, getZipFileName(business))
  }

  const generateZip = async (zip: JSZip) => {
    const blobResult = await zip.generateAsync({ type: 'blob' })
    setBlob(blobResult)
    _onSuccess()
    _saveAs(blobResult)
  }

  const downloadZip = () => {
    if (blob) _saveAs(blob)
  }

  const fetchFileAsBlob = async (file: IFile): Promise<File> => {
    const response = await axios.get(file.file, {
      decompress: false,
      responseType: 'arraybuffer'
    })
    const fileData = new Blob([response.data], { type: file.type })
    return new File([fileData], `${getZipFileName(business)}/${getFileName(file)}`, {
      type: file.type
    })
  }

  const fetchPages = useCallback(
    async (startPage: number = 1): Promise<JSZip> => {
      const zip = new JSZip()
      let currentPage = startPage
      let hasNextPage = true

      while (hasNextPage) {
        const data = await fetchFiles({ businessId }, { page: currentPage, page_size: 50 })
        dispatch({ type: 'SET_TOTAL_FILES', payload: data.count })

        // If any file fails, Promise.all rejects and the process stops.
        const filePromises = data.results.map(async file => {
          const downloadedFile = await fetchFileAsBlob(file)
          dispatch({ type: 'INCREMENT_FILES_DOWNLOADED' })
          return downloadedFile
        })

        const downloadedFiles = await Promise.all(filePromises)
        downloadedFiles.forEach(downloadedFile => {
          zip.file(downloadedFile.name, downloadedFile)
        })

        if (data.next) {
          currentPage = data.next
        } else {
          hasNextPage = false
        }
      }
      return zip
    },
    [businessId, business]
  )

  const startZipGeneration = useCallback(async () => {
    try {
      setBlob(null)
      dispatch({ type: 'START' })
      const zip = await fetchPages()
      await generateZip(zip)
    } catch (error) {
      console.error('Error during zip download:', error)
      _onError()
    }
  }, [fetchPages, business])

  const reset = useCallback(() => {
    setBlob(null)
    dispatch({ type: 'RESET' })
  }, [])

  return {
    state,
    progress,
    startZipGeneration: startZipGeneration,
    downloadZip,
    reset
  }
}
