import { BlueprintType } from '@constants'
import { IAccountingSuggestionUIRow } from '@query/suggestions'
import { network } from '@utils'
import { IFile } from './files'
import {
  ActionFunction,
  handleReturn,
  MutationFunction,
  PaginatedResponse,
  QueryFunction
} from './root'
import { ITag } from './tags'

export interface IImportData {
  id: number
  trace_id: string
  identifier: string
  amount: number
  date: string
  description: string
  contact_hint: string
  reference_number?: string
  payment_account_id: number
  balance_after_transaction?: number
  algo_status: string
  algo_trial_restricted: boolean
}

export interface IBlueprintEntry {
  account_id?: number
  description?: string
  vat_code?: number
  vat_rate?: number
  vat_method?: string
  amount: number
}

export interface IBlueprint {
  debet_account_id?: number
  debet_entries?: IBlueprintEntry[]
  credit_account_id?: number
  credit_entries?: IBlueprintEntry[]
  expense_entries?: IBlueprintEntry[]
}

export interface IDocument {
  id: number
  date: string
  tags: ITag[]
  contact_id: number
  contact_name: string
  balance: number
  period: number
  number: string
  description: string
  is_flagged: boolean
  is_locked: boolean
  is_draft: boolean
  type: number
  updated_at: string
  created_at: string
  blueprint: IBlueprint
  blueprint_type: BlueprintType
  attachment_ids: number[]
  import_data?: IImportData
  has_ai_generated_description: boolean
  entry_ui_rows: IAccountingSuggestionUIRow[]
  audit_trail_last_seen_at: string | null
  audit_trail_last_mention_at: string | null
  audit_trail_last_comment_at: string | null
  audit_trail_has_unseen_actions: boolean
}

interface DocumentListProps {
  businessId: string
}

interface DocumentInstanceProps extends DocumentListProps {
  documentId: number
}

export const fetchDocuments: QueryFunction<
  DocumentListProps,
  PaginatedResponse<IDocument>
> = async ({ businessId }, params = null) => {
  const url = `/v1/business/${businessId}/document/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createDocument: MutationFunction<DocumentListProps, IDocument> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/document/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const fetchDocument: QueryFunction<DocumentInstanceProps, IDocument> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updateDocument: MutationFunction<DocumentInstanceProps, IDocument> = async (
  { businessId, documentId },
  requestData
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteDocument: MutationFunction<DocumentInstanceProps, void> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE',
    params
  })
}

interface IMassDeleteProps {
  id: number
}

interface IMassDeleteResult {
  delete: number
  locked: number
}

export const massDeleteDocuments: ActionFunction<
  DocumentListProps,
  IMassDeleteProps[],
  IMassDeleteResult
> = async ({ businessId }, requestData) => {
  const url = `/v1/business/${businessId}/document/actions/delete/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData
  })

  return data
}

// ACTIONS

export const lockDocument: MutationFunction<DocumentInstanceProps, void> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/lock/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    params
  })
  return data
}

export const unlockDocument: MutationFunction<DocumentInstanceProps, void> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/unlock/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    params
  })
  return data
}

interface DocumentCopyProps extends DocumentInstanceProps {
  sourceId: number
}

export const copyDocument: MutationFunction<DocumentCopyProps, void> = async ({
  businessId,

  documentId,
  sourceId
}) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/copy/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    params: { source: sourceId }
  })
  return data
}

export const attachFiles: MutationFunction<DocumentInstanceProps, number[]> = async (
  { businessId, documentId },
  fileIds
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/attach_files/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: fileIds
  })
  return data
}

export const detachFiles: MutationFunction<DocumentInstanceProps, number[]> = async (
  { businessId, documentId },
  fileIds
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/detach_files/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: fileIds
  })
  return data
}

export const setFiles: MutationFunction<DocumentInstanceProps, number[]> = async (
  { businessId, documentId },
  fileIds
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/set_files/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: fileIds
  })
  return data
}

export const flagDocument: MutationFunction<DocumentInstanceProps, void> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/flag/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    params
  })
  return data
}

export const unflagDocument: MutationFunction<DocumentInstanceProps, void> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/action/unflag/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    params
  })
  return data
}

interface SimilarityProps {
  businessId: string
  search: string
  importModelId?: number
}

export const fetchSimilarDocuments: QueryFunction<SimilarityProps, IDocument[]> = async ({
  businessId,
  search,
  importModelId
}) => {
  const url = `/v1/business/${businessId}/document_similarity/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { query: search, import_model: importModelId }
  })
  return data
}

export const fetchSuggestedAttachments: QueryFunction<DocumentInstanceProps, IFile[]> = async ({
  businessId,
  documentId
}) => {
  const url = `/v1/business/${businessId}/document/${documentId}/suggest_attachments/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
