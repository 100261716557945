import { ModalV2, StepBlock, ToolBar } from '@components'
import { useBusinessContext, useFeature } from '@context'
import { IVatPeriod, lockVatPeriod, reportVatPeriod } from '@query'
import { formatDate } from '@root/utils'
import { Pane, Text } from 'evergreen-ui'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaLock } from 'react-icons/fa'

import { useMutation, useQueryClient } from 'react-query'
import VatReportModal from './VatReportModal'

interface Props {
  vatPeriod: IVatPeriod
}

export const VatPeriodSteps: React.FC<Props> = ({ vatPeriod }) => {
  const queryClient = useQueryClient()
  const { businessId, data: business } = useBusinessContext()
  const [showReportModal, setShowReportModal] = React.useState(false)
  const [t] = useTranslation()
  const has_verohallinto_feature_flag = useFeature('feature_verohallinto_vat_report')

  const lockMutation = useMutation(() => lockVatPeriod({ businessId, vatPeriodId: vatPeriod.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'vat_periods'])
      queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriod.id])
      toast.success(t('vatPage.lockSuccess'))
    },
    onError: ({ data }) => {
      if ('has_drafts' in data) {
        toast.error(t('vatPage.hasDrafts'))
      } else {
        toast.error(t('error.oho'))
      }
    }
  })

  const reportMutation = useMutation(
    () => reportVatPeriod({ businessId, vatPeriodId: vatPeriod.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'vat_periods'])
        queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriod.id])
        toast.success(t('vatPage.reportSuccess'))
      },
      onError: () => {
        toast.error(t('error.oho'))
      }
    }
  )

  const LockDescription: React.FC = () => {
    return (
      <Pane>
        <Pane marginBottom={8}>
          <Text>{t('vatPage.verify')}</Text>
        </Pane>
        <ToolBar
          align="left"
          items={[
            {
              text: t('vatPage.lock'),
              icon: FaLock,
              primary: true,
              disabled: vatPeriod.is_locked,
              action: () => {
                lockMutation.mutate()
              }
            }
          ]}
        />
      </Pane>
    )
  }

  const AccountDescription: React.FC = () => {
    return (
      <Pane display="flex" flexDirection="column">
        <Pane marginBottom={8}>
          <Text>
            {has_verohallinto_feature_flag
              ? t('vatPage.descReportSendable')
              : t('vatPage.descReport')}
          </Text>
        </Pane>
        <ToolBar
          align="left"
          items={[
            {
              text: has_verohallinto_feature_flag
                ? t('vatPage.reportPeriod')
                : t('vatPage.markReported'),
              icon: FaCheckCircle,
              primary: true,
              disabled: vatPeriod.is_reported,
              action: () => {
                business.country_config.feature.verohallinto && has_verohallinto_feature_flag
                  ? setShowReportModal(true)
                  : reportMutation.mutate()
              }
            }
          ]}
        />
      </Pane>
    )
  }

  return (
    <>
      <StepBlock
        title={t('vatPage.waitForEnd')}
        isBlocked={false}
        descComplete={
          <Text color="dimmed">
            {t('vatPage.periodHasEnded', { date: formatDate(vatPeriod.end_date) })}
          </Text>
        }
        isCompleted={vatPeriod.has_ended}
      />

      <StepBlock
        title={t('vatPage.report')}
        descCurrent={<AccountDescription />}
        descComplete={<Text color="dimmed">{t('vatPage.hasBeenReported')}</Text>}
        isBlocked={!vatPeriod.has_ended}
        isCompleted={vatPeriod.is_reported}
      />

      <StepBlock
        isLast={true}
        title={t('vatPage.lock')}
        descCurrent={<LockDescription />}
        descComplete={<Text color="dimmed">{t('vatPage.periodIsLocked')}</Text>}
        isBlocked={!vatPeriod.is_reported}
        isCompleted={vatPeriod.is_locked}
      />

      <ModalV2
        isVisible={showReportModal}
        header={t('vatPage.vatReport.header')}
        handleOnClose={() => setShowReportModal(false)}
        width={430}
        height={700}
      >
        <VatReportModal onClose={() => setShowReportModal(false)} vatPeriod={vatPeriod} />
      </ModalV2>
    </>
  )
}
