import { NocfoPlan } from '@constants'
import { network } from '@utils'
import { QueryFunction } from './root'

export interface ICustomerPortalSession {
  id: string
  url: string
}

interface BillingProps {
  businessId: string
}

export const fetchCustomerPortalSession: QueryFunction<
  BillingProps,
  ICustomerPortalSession
> = async ({ businessId }) => {
  const url = `/v1/billing/${businessId}/stripe/customer_portal/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface ISubscription {
  plan: string
  is_active: string
}

export const fetchSubscription: QueryFunction<BillingProps, ISubscription> = async ({
  businessId
}) => {
  const url = `/v1/billing/${businessId}/subscription/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface CheckoutSessionProps {
  businessId: string
  stripePriceId: string
}

export interface ICheckoutSession {
  id: string
  url: string
}

export const fetchCheckoutSessionUrl: QueryFunction<
  CheckoutSessionProps,
  ICheckoutSession
> = async ({ businessId, stripePriceId }) => {
  const url = `/v1/billing/${businessId}/stripe/checkout_link/${stripePriceId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface IPrice {
  name: string
  description: string
  stripe_price_id: string
  plan: NocfoPlan
}

export const fetchSubscriptionPrices: QueryFunction<BillingProps, IPrice[]> = async ({
  businessId
}) => {
  const url = `/v1/billing/${businessId}/stripe/prices/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface IPricingTableKeys {
  pricing_table_id: string
  pricing_table_publishable_key: string
  pricing_table_customer_session_client_secret: string
}

interface PricingTableProps {
  businessId: string
}

export const fetchPricingTableKeys: QueryFunction<PricingTableProps, IPricingTableKeys> = async ({
  businessId
}) => {
  const url = `/v1/billing/${businessId}/stripe/pricing_table/keys/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface ICheckoutSessionProps {
  sessionId: string
}

export interface ICheckoutSessionResultProps {
  business_id: string
}

export const completeCheckoutSession: QueryFunction<
  ICheckoutSessionProps,
  ICheckoutSessionResultProps
> = async ({ sessionId }) => {
  const url = `/v1/billing/stripe/pricing_table/complete/${sessionId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
