import { BasicPage } from '@components'
import { Login } from '@containers/Login'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const SignupPage: React.FC = () => {
  const [t] = useTranslation()

  return (
    <BasicPage>
      <Container>
        <h2>{t('signup.title')}</h2>
        <p>{t('signup.description')}</p>
        <Login isRegister={true} />
        <div style={{ textAlign: 'center' }}>
          <Trans i18nKey="signup.gotAccount">
            <Link
              to={{
                pathname: '/',
                search: window.location.search
              }}
            />
          </Trans>
        </div>
      </Container>
    </BasicPage>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg}rem;

  & > * {
    margin: 0;
  }
`
