import { AnimatedContentLoader, Button, Select, Switch, TextTooltip } from '@components'
import { useBusinessContext } from '@context'
import {
  SlideContent,
  SlideFooter,
  SlideTitle,
  Spacer
} from '@pages/BankIntegrationOnboardingPage/slides/slide.styled'
import { EnableBankingPSUType, fetchInstitutions, fetchRequisition } from '@query'
import cn from 'classnames'
import { motion } from 'framer-motion'
import countries from 'i18n-iso-countries'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight, FaQuestionCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { HolviAuthorizeButton } from '@containers/HolviAuthorizeButton/HolviAuthorizeButton.tsx'
import { theme } from '@root/styles'

enum EnableBankingCountry {
  Austria = 'AT',
  Belgium = 'BE',
  CzechRepublic = 'CZ',
  Germany = 'DE',
  Denmark = 'DK',
  Estonia = 'EE',
  Spain = 'ES',
  Finland = 'FI',
  France = 'FR',
  Greece = 'GR',
  Hungary = 'HU',
  Ireland = 'IE',
  Iceland = 'IS',
  Italy = 'IT',
  Lithuania = 'LT',
  Latvia = 'LV',
  Malta = 'MT',
  Netherlands = 'NL',
  Norway = 'NO',
  Poland = 'PL',
  Portugal = 'PT',
  Sweden = 'SE',
  Slovenia = 'SI',
  Slovakia = 'SK'
}

interface Props {
  goBack: () => void
}

export const AspspsSlide: React.FC<Props> = ({ goBack }) => {
  const {
    t,
    i18n: { language: currentLanguage }
  } = useTranslation()
  const [selected, setSelected] = useState<[string, string]>()
  const [psuType, setPsuType] = useState<EnableBankingPSUType>(EnableBankingPSUType.BUSINESS)
  const [country, setCountry] = useState<EnableBankingCountry>(EnableBankingCountry.Finland)
  const { businessId } = useBusinessContext()

  const { data: institutions, isLoading } = useQuery(
    [businessId, 'institutions', country, psuType],
    () => fetchInstitutions({ businessId, country, psuType })
  )

  const { data: requisition, isFetching: isRequisitionLoading } = useQuery(
    [businessId, 'requisition', selected, psuType],
    () =>
      fetchRequisition({
        businessId,
        aspspCountry: selected?.[0],
        aspspName: selected?.[1],
        psuType: psuType
      }),
    {
      enabled: !!selected
    }
  )

  const isLinkDisabled = isRequisitionLoading || !requisition?.url
  const isHolviSelected = selected?.[1]?.toLowerCase() === 'holvi'

  return (
    <>
      <SlideTitle>{t('settings.integrations.bank.createModalTitle')}</SlideTitle>
      <Select
        name="aspsp-country"
        label={t('settings.integrations.bank.selectCountry')}
        onChange={e => setCountry(e.target.value)}
      >
        {Object.keys(EnableBankingCountry).map(key => (
          <option
            selected={country === EnableBankingCountry[key]}
            key={EnableBankingCountry[key]}
            value={EnableBankingCountry[key]}
          >
            {countries.getName(EnableBankingCountry[key], currentLanguage)}
          </option>
        ))}
      </Select>

      <SwitchWrapper>
        <Switch
          id="psu-type-switch"
          label={t('settings.integrations.bank.psuLabel')}
          name="psu-type-switch"
          checked={psuType === EnableBankingPSUType.PERSONAL}
          onChange={e => {
            const isPersonal = e.target.checked
            setPsuType(isPersonal ? EnableBankingPSUType.PERSONAL : EnableBankingPSUType.BUSINESS)
          }}
        />

        <TextTooltip tooltip={t('settings.integrations.bank.psuTooltip')}>
          <FaQuestionCircle />
        </TextTooltip>
      </SwitchWrapper>
      <SlideContent>
        <AnimatedContentLoader isLoading={isLoading}>
          {institutions?.map(institution => (
            <InstitutionItem
              key={institution.name}
              data-key={institution.name}
              onClick={e => {
                setSelected([institution.country, institution.name])
                e.preventDefault()
              }}
              className={cn({ isSelected: selected?.[1] === institution.name })}
            >
              <InstitutionLogo src={institution.logo} />
              <InstitutionContent>
                <InstitutionName>{institution.name}</InstitutionName>
              </InstitutionContent>
            </InstitutionItem>
          ))}
        </AnimatedContentLoader>
      </SlideContent>

      <SlideFooter>
        <InnerFooterContainer>
          <Row style={{ marginBottom: `${theme.spacing.md}rem` }}>
            <a href={t('link.docs.cantFindBank')} rel="noreferrer" target="_blank">
              {t('settings.integrations.bank.cantFindBank')}
            </a>
          </Row>

          <Row>
            <Button onClick={() => goBack()}>{t('general.back')}</Button>
            <Spacer />
            {isHolviSelected ? (
              <HolviAuthorizeButton />
            ) : (
              <a href={isLinkDisabled ? null : requisition?.url}>
                <Button
                  disabled={isLinkDisabled}
                  showSpinner={isRequisitionLoading}
                  iconRight={<FaArrowRight />}
                  style={{ justifyContent: 'center', width: '100%' }}
                  intent="primary"
                >
                  {t('settings.integrations.bank.createButton')}
                </Button>
              </a>
            )}
          </Row>
        </InnerFooterContainer>
      </SlideFooter>
    </>
  )
}

const InnerFooterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const InstitutionItem = styled(motion.a).attrs({
  href: '#'
})`
  background: transparent;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutralBlack};
  text-decoration: none !important;

  &.isSelected {
    background: ${({ theme }) => theme.colors.neutralGray};
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutralGray};
  }
`

const InstitutionLogo = styled.div<{ src: string }>`
  border: 0.1rem solid white;
  width: 2.5rem;
  height: 2.5rem;
  background: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.4rem;
`

const InstitutionContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const InstitutionName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;

  & > * {
    margin: 0;
  }

  svg {
    fill: ${({ theme }) => theme.colors.metalGray};
  }
`
