import integrationImg from '@assets/undraw/undraw_online_connection_6778.svg'
import { Button, TeaserView } from '@components'
import { FeatureWrapper, useBusinessContext } from '@context'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RequisitionModal } from './RequisitionModal'

export const BankIntegrationLander: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  return (
    <>
      <FeatureWrapper feature="feature_bank_integrations">
        <StyledLander>
          <AnimatePresence>
            <AnimatedImg src={integrationImg} />
          </AnimatePresence>

          <h2>{t('settings.integrations.bank.createTitle')}</h2>

          <p>{t('settings.integrations.bank.createDescription1')}</p>

          <p>
            <Trans
              i18nKey={t('settings.integrations.bank.createDescription2')}
              components={[
                <a
                  key="docs-bank-integration"
                  href={t('link.docs.addingBankIntegration')}
                  target="_blank"
                  rel="noreferrer"
                />
              ]}
            ></Trans>
          </p>

          <RequisitionModal>
            <Link to={`/${businessId}/bank-integration-flow/aspsps`}>
              <Button icon={<FaPlus />} intent="primary">
                {t('settings.integrations.bank.createButton')}
              </Button>
            </Link>
          </RequisitionModal>
        </StyledLander>
      </FeatureWrapper>

      <FeatureWrapper feature="feature_bank_integrations" reverse>
        <TeaserView
          header={t('teaser.bankIntegration.header')}
          subHeader={t('teaser.bankIntegration.subHeader')}
          features={[
            t('teaser.feature.4'),
            t('teaser.feature.6'),
            t('teaser.feature.5'),
            t('teaser.feature.1'),
            t('teaser.feature.2')
          ]}
        />
      </FeatureWrapper>
    </>
  )
}

const StyledLander = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.lg}rem;

  img {
    width: 100%;
    max-width: 300px;
    margin: ${({ theme }) => theme.spacing.xxl}rem;
    margin-top: ${({ theme }) => theme.spacing.md}rem;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    max-width: 400px;
    color: ${({ theme }) => theme.colors.metalGray};
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  }

  button {
    margin-top: 1rem;
  }
`

const AnimatedImg = styled(motion.img).attrs({
  initial: { opacity: 0, scale: 0.8, y: 50, rotate: 15 },
  animate: { opacity: 1, scale: 1, y: 0, rotate: 0 },
  transition: { delay: 0.1 }
})``
