import { ReportType } from '@root/constants'
import { useBusinessContext } from '@root/context'
import { IVatPeriod } from '@root/query'
import { generateReport } from '@root/utils/generateReport'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  vatPeriod: IVatPeriod
}

const VatReportLink: React.FC<Props> = ({ vatPeriod }) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()

  const handleLinkClick = () => {
    generateReport({
      business_id: businessId,
      type: ReportType.VAT_REPORT,
      columns: [
        {
          date_from: vatPeriod.start_date,
          date_to: vatPeriod.end_date
        }
      ]
    })
  }

  return (
    <StyledButton onClick={() => handleLinkClick()} type="button">
      {t('vatPage.link')}
    </StyledButton>
  )
}

export default VatReportLink

const StyledButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.nocfoBlue};
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
`
