import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Alert, Button, Progress, Prompt, ToggleContainer } from '@components'
import { useTheme } from '@hooks'
import { useDownloadFilesAsZip } from '@hooks/useDownloadFilesAsZip.ts'
import { FaDownload } from 'react-icons/fa6'
import { Spinner } from 'evergreen-ui'
import toast from 'react-hot-toast'
import { FaUndo } from 'react-icons/fa'

export const DataExport: React.FC = () => {
  const [t] = useTranslation()
  const theme = useTheme()
  const { startZipGeneration, downloadZip, state, progress, reset } = useDownloadFilesAsZip({
    onSuccess: () => {
      toast.success(t('settings.dataExport.exportFiles.success'))
    },
    onError: () => {
      toast.error(t('general.error'))
    }
  })

  // Progress is shown when loading or in terminal state
  const showProgress = state.isLoading || state.isSuccess || state.isError

  return (
    <StyledWrapper>
      <h2>{t('settings.dataExport.mainTitle')}</h2>

      <ToggleContainer title={t('settings.dataExport.exportFiles.title')} openByDefault={true}>
        <Alert description={t('settings.dataExport.exportFiles.description')} />

        <Prompt
          isVisible={showProgress}
          onClose={() => reset()}
          title={t('settings.dataExport.exportFiles.title')}
          description={
            <>
              <ProgressWrapper>
                <Progress
                  progressColor={
                    state.isSuccess
                      ? theme.colors.nocfoGreen
                      : state.isError
                      ? theme.colors.nocfoRed
                      : theme.colors.nocfoBlue
                  }
                  percent={progress}
                />
                {state.isSuccess && (
                  <LoadingText>{t('settings.dataExport.exportFiles.clickToDownload')}</LoadingText>
                )}
                {state.isError && (
                  <LoadingText>{t('settings.dataExport.exportFiles.error')}</LoadingText>
                )}
                {state.isLoading && (
                  <LoadingText>
                    <Spinner size={12} /> {t('settings.dataExport.exportFiles.downloading')}
                  </LoadingText>
                )}
              </ProgressWrapper>
            </>
          }
          buttons={[
            {
              intent: 'default',
              text: t('general.close'),
              action: () => {
                reset()
              }
            },
            !state.isError
              ? {
                  intent: 'primary',
                  action: () => downloadZip(),
                  disabled: !state.isSuccess,
                  icon: <FaDownload />,
                  text: t('general.save')
                }
              : {
                  intent: 'danger',
                  action: () => {
                    reset()
                    startZipGeneration()
                  },
                  disabled: !state.isSuccess,
                  icon: <FaUndo />,
                  text: t('general.retry')
                }
          ]}
        />

        <Button
          showSpinner={state.isLoading}
          disabled={state.isLoading}
          onClick={() => {
            startZipGeneration()
          }}
          icon={<FaDownload />}
        >
          {t('settings.dataExport.exportFiles.button')}
        </Button>
      </ToggleContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  }
`

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  padding: ${({ theme }) => theme.spacing.lg}rem 0;
`

const LoadingText = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  align-items: center;
`
