import { AnimatedContentLoader, Button, Checkbox, Input } from '@root/components'
import { useBusinessContext } from '@root/context'
import {
  checkVatReportingAuthorization,
  IVatPeriod,
  IVatReporterContactDetails,
  reportVatPeriod,
  reportVatPeriodToVerohallinto
} from '@root/query'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { FaPaperPlane } from 'react-icons/fa6'
import { get, useForm } from 'react-hook-form'
import VatAuhtorization from './VatAuhtorization'
import VatReportLink from './VatReportLink'
import { setAPIErrors } from '@root/utils'

interface Props {
  vatPeriod: IVatPeriod
  onClose: () => void
}

const VatReportModal: React.FC<Props> = ({ vatPeriod, onClose }) => {
  const queryClient = useQueryClient()
  const { businessId, data: business } = useBusinessContext()
  const [t] = useTranslation()
  const [isToVerohallinto, setIsToVerohallinto] = React.useState<boolean>(true)
  const [hasAuthorizedVatReporting, setHasAuthorizedVatReporting] = React.useState<boolean>(false)

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError
  } = useForm<IVatReporterContactDetails>({
    defaultValues: {
      verohallinto_latest_reporter_full_name:
        business?.verohallinto_latest_reporter_full_name ?? '',
      verohallinto_latest_reporter_phone_number:
        business?.verohallinto_latest_reporter_phone_number ?? ''
    }
  })

  const reportToVerohallintoMutation = useMutation(
    (reportedDetails: IVatReporterContactDetails) =>
      reportVatPeriodToVerohallinto({ businessId, vatPeriodId: vatPeriod.id }, reportedDetails),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriod.id])
        toast.success(t('vatPage.vatReport.form.success'))
        onClose()
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
          return
        }
        toast.error(t('error.oho'))
      }
    }
  )

  const reportMutation = useMutation(
    () => reportVatPeriod({ businessId, vatPeriodId: vatPeriod.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'vat_periods'])
        queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriod.id])
        toast.success(t('vatPage.vatReport.form.success'))
      },
      onError: () => {
        toast.error(t('error.oho'))
      }
    }
  )

  const { isLoading: isAuthCheckLoading } = useQuery(
    [businessId, 'vat_report_authorization'],
    () => checkVatReportingAuthorization({ businessId }),
    {
      retry: 1, // No need to spam the API
      onSuccess: ({ ok }) => {
        setHasAuthorizedVatReporting(ok)
      }
    }
  )

  const markVatPeriodReported = () => {
    reportMutation.mutate()
    onClose()
  }

  const onSubmit = (data: IVatReporterContactDetails) => {
    reportToVerohallintoMutation.mutate(data)
  }

  return (
    <StyledModalContainer>
      <AnimatedContentLoader isLoading={isAuthCheckLoading}>
        <div>
          <p>{t('vatPage.vatReport.info')}</p>

          <Checkbox
            checked={isToVerohallinto}
            onChange={() => setIsToVerohallinto(!isToVerohallinto)}
            label={t('vatPage.vatReport.form.checkBox')}
            id="vat-report-to-verohallinto-checkbox"
          ></Checkbox>

          {isToVerohallinto && (
            <>
              <StyledAuthorizationWrapper>
                <VatAuhtorization hasAuthorization={hasAuthorizedVatReporting} />
              </StyledAuthorizationWrapper>
              <form id="vat-report-form-to-verohallinto" onSubmit={handleSubmit(onSubmit)}>
                <Input
                  {...register('verohallinto_latest_reporter_full_name', {
                    required: { value: true, message: t('validation.required') }
                  })}
                  label={t('vatPage.vatReport.form.contactName')}
                  autoFocus
                  required
                  errors={get(errors, 'verohallinto_latest_reporter_full_name')}
                  disabled={!hasAuthorizedVatReporting}
                  info={t('vatPage.vatReport.form.nameInfo')}
                ></Input>
                <Input
                  {...register('verohallinto_latest_reporter_phone_number', {
                    required: { value: true, message: t('validation.required') }
                  })}
                  label={t('vatPage.vatReport.form.contactPhone')}
                  required
                  errors={get(errors, 'verohallinto_latest_reporter_phone_number')}
                  disabled={!hasAuthorizedVatReporting}
                  info={t('vatPage.vatReport.form.phoneInfo')}
                ></Input>
              </form>
              <StyledReportLinkWrapper>
                <VatReportLink vatPeriod={vatPeriod} />
              </StyledReportLinkWrapper>
            </>
          )}
        </div>
      </AnimatedContentLoader>

      <StyledFooter>
        <Button onClick={() => onClose()}>{t('general.close')}</Button>

        <ActionButtons>
          {isToVerohallinto ? (
            <StyledSendFormButton
              icon={<FaPaperPlane />}
              intent="success"
              type="submit"
              form="vat-report-form-to-verohallinto"
              disabled={
                !hasAuthorizedVatReporting ||
                reportMutation.isLoading ||
                reportToVerohallintoMutation.isLoading
              }
              onClick={() => handleSubmit(onSubmit)}
              showSpinner={reportToVerohallintoMutation.isLoading}
            >
              {t('vatPage.vatReport.form.submit')}
            </StyledSendFormButton>
          ) : (
            <Button
              intent="success"
              onClick={() => markVatPeriodReported()}
              icon={<FaCheckCircle />}
              disabled={reportMutation.isLoading || reportToVerohallintoMutation.isLoading}
            >
              {t('vatPage.markReported')}
            </Button>
          )}
        </ActionButtons>
      </StyledFooter>
    </StyledModalContainer>
  )
}

export default VatReportModal

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledSendFormButton = styled(Button)`
  margin-left: auto;
`

const StyledFooter = styled.div`
  display: flex;
  height: fit-content;
  margin-top: auto;
  justify-content: space-between;
`
const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
const StyledAuthorizationWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
`
const StyledReportLinkWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`
