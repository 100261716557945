import { Alert, Button, FlipCard, Input, MonetaryAmount, TextTooltip } from '@components'
import { getContactPageUrl, VatCode, VAT_CODE_OPTIONS } from '@constants'
import { AttachmentSelector } from '@containers/AttachmentSelector'
import { useBusinessContext, useFeature } from '@context'
import { SuggestionFormModal } from '@pages/DocumentPage/DocumentDetails/AccountingSuggestions/SuggestionFormModal'
import { IAccountingSuggestion } from '@query/suggestions'
import { formatCurrency, formatDate } from '@utils'
import { motion } from 'framer-motion'
import React, { useCallback, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaAddressCard, FaArrowLeft, FaCheck, FaInfoCircle, FaPen, FaShare } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAllowUpload } from '@root/hooks/useAllowUpload'

interface SuggestionCardProps {
  suggestion: IAccountingSuggestion
  onAccept: (suggestionId: number, modifiedData?: Partial<IAccountingSuggestion>) => Promise<any>
}

export const SuggestionCard: React.FC<SuggestionCardProps> = ({ suggestion, onAccept }) => {
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()
  const acceptButtonRef = useRef(null)
  const [showEdit, setShowEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<Partial<IAccountingSuggestion>>({
    defaultValues: {
      description: suggestion.description,
      attachment_ids: suggestion?.attachment_ids || []
    }
  })

  const getShortVatCodeText = useCallback((vatCode: VatCode) => {
    return t(VAT_CODE_OPTIONS.find(({ value }) => value === vatCode).labelShort)
  }, [])

  const handleAccept = handleSubmit(data => {
    setLoading(true)
    onAccept(suggestion.id, data).finally(() => setLoading(false))
  })

  const isAllowedToUpload = useAllowUpload()
  const hasUnlimitedStorage = useFeature('feature_unlimited_storage', true)
  const hideFileSection = !hasUnlimitedStorage && suggestion?.attachment_ids?.length === 0

  return (
    <>
      <StyledCardContainer>
        <div style={{ width: '100%' }}>
          <Alert
            type={'magic'}
            title={t('document.suggestionInstruction.title')}
            description={
              <>
                <p>{t('document.suggestionInstruction.description')}</p>
                <a
                  href={t('link.suggestions')}
                  style={{ fontWeight: 500 }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('document.suggestionInstruction.readMore')} {'→'}
                </a>
              </>
            }
            dismissable={true}
            dismissableKeyDotPath={`${businessId}.suggestions.instructionDismissed`}
          />
        </div>

        <StyledCardWrapper key={`suggestion-${suggestion.id}`}>
          <FlipCard
            maxPerspectiveRotation={3}
            rotationFactor={10}
            enableFlipping={false}
            enableMovement={true}
            renderFront={({ flip }) => (
              <StyledCard>
                <form id="suggestion-form" style={{ width: '100%' }} onSubmit={handleAccept}>
                  <StyledCardIcon className={suggestion.amount >= 0 ? 'positive' : 'negative'}>
                    <FaShare />
                  </StyledCardIcon>

                  <StyledFlipButton onClick={() => flip()}>
                    <TextTooltip tooltip={t('document.clickToSeeBankDetails')} openDelay={800}>
                      <FaInfoCircle />
                    </TextTooltip>
                  </StyledFlipButton>

                  <CardHeader>
                    <CardDate>{formatDate(suggestion.date)}</CardDate>
                    <CardAmount>{formatCurrency(suggestion.amount)}</CardAmount>
                    <CardDescription>
                      <Input
                        label={t('document.description')}
                        {...register('description', {
                          required: { value: true, message: t('validation.required') }
                        })}
                        errors={errors.description}
                        hideErrorText={true}
                        placeholder={t('document.descriptionPlaceholder')}
                      />
                    </CardDescription>
                  </CardHeader>

                  <Hr />
                  <ContactWrapper>
                    <ContactIconWrapper>
                      <FaAddressCard />
                    </ContactIconWrapper>
                    <ContactName>
                      {suggestion.contact ? (
                        <Link to={getContactPageUrl(businessId, { id: suggestion.contact })}>
                          {suggestion.contact_name}
                        </Link>
                      ) : (
                        suggestion.contact_name
                      )}
                    </ContactName>
                  </ContactWrapper>
                  <Hr />

                  {suggestion.ui_rows.map((row, index) => (
                    <StyledUIRow key={`row-${index}`}>
                      <div className="row">
                        <AccountName>
                          {row.account_number} {row.account_name}
                        </AccountName>
                        <MonetaryAmount value={row.amount} />
                      </div>
                      <div className="row">
                        <StyledBadge className="vat_code">
                          {getShortVatCodeText(row.vat_code ?? VatCode.TAX_FREE)}
                        </StyledBadge>
                        {row.vat_code && row.vat_code !== VatCode.TAX_FREE && (
                          <StyledBadge className="vat_rate">
                            {t('general.vat', { percentage: row.vat_rate })}
                          </StyledBadge>
                        )}
                      </div>
                    </StyledUIRow>
                  ))}

                  {!hideFileSection && (
                    <>
                      <Hr />
                      <AttachmentSelectorWrapper>
                        <Controller
                          name="attachment_ids"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <AttachmentSelector
                              value={value}
                              onChange={onChange}
                              showRemoveInsteadOfViewLink={true}
                              disabled={!isAllowedToUpload}
                            />
                          )}
                        />
                      </AttachmentSelectorWrapper>
                    </>
                  )}
                </form>
              </StyledCard>
            )}
            renderBack={({ flip }) => {
              return (
                <StyledCard>
                  <StyledBackButton onClick={() => flip()}>
                    <FaArrowLeft />
                  </StyledBackButton>

                  <StyledImportData>
                    <h4>{t('document.importDetails.date')}</h4>
                    <p>{formatDate(suggestion.import_data.date)}</p>

                    <h4>{t('document.importDetails.amount')}</h4>
                    <p>{formatCurrency(suggestion.import_data.amount)}</p>

                    {suggestion.import_data.contact_hint && (
                      <>
                        <h4>{t('document.importDetails.contact')}</h4>
                        <p>{suggestion.import_data.contact_hint}</p>
                      </>
                    )}

                    {suggestion.import_data.description && (
                      <>
                        <h4>{t('document.importDetails.description')}</h4>
                        <p>{suggestion.import_data.description}</p>
                      </>
                    )}

                    {suggestion.import_data.reference_number && (
                      <>
                        <h4>{t('document.importDetails.reference')}</h4>
                        <p>{suggestion.import_data.reference_number}</p>
                      </>
                    )}
                  </StyledImportData>
                </StyledCard>
              )
            }}
          />
        </StyledCardWrapper>

        <StyledCardFooter>
          <Button disabled={isLoading} onClick={() => setShowEdit(true)} icon={<FaPen />}>
            {t('general.edit')}
          </Button>

          <div style={{ flex: 1 }} />

          <Button
            ref={acceptButtonRef}
            intent="success"
            type="submit"
            form={'suggestion-form'}
            disabled={isLoading}
            iconRight={<FaCheck />}
          >
            {t('document.acceptSuggestion')}
          </Button>
        </StyledCardFooter>
      </StyledCardContainer>

      <SuggestionFormModal
        isVisible={showEdit}
        onClose={() => setShowEdit(false)}
        suggestion={suggestion}
        onCreate={data => onAccept(suggestion.id, data)}
      />
    </>
  )
}

const StyledCardWrapper = styled(motion.div)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.xl}rem;
  padding-bottom: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
`

const StyledCardContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const StyledCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm}rem 0;
  color: ${({ theme }) => theme.colors.neutralBlack};
  background: white;
  border-radius: 1rem;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
`

const CardHeader = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  width: 100%;
`

const CardDate = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const CardAmount = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xl}rem;
  font-weight: 500;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const CardDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

    .input-wrapper {
      padding: ${({ theme }) => theme.spacing.sm}rem;
    }
  }
`

const ContactWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.sm}rem;

  svg {
    display: block;
  }
`

const ContactName = styled.div`
  font-weight: 500;

  a {
    color: ${({ theme }) => theme.colors.neutralBlack} !important;
  }
`

const ContactIconWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg}rem;
`

const Hr = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
`

const StyledUIRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  & > .row {
    width: 100%;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs}rem;
  }
`

const AccountName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: bold;
  flex: 1;
`

const StyledCardIcon = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.flipped {
    opacity: 0;
  }

  & > svg {
    width: 2rem;
    height: 2rem;
  }

  &.negative {
    background: #fbe3a6;

    & > svg {
      fill: #dfae22;
    }
  }

  &.positive {
    background: #c7ffdc;

    & > svg {
      fill: #39d273;
      transform: rotateY(180deg);
    }
  }
`

const StyledBadge = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 0.5rem;
  letter-spacing: 0.5px;

  &.vat_rate {
    background: ${({ theme }) => theme.colors.nocfoGreen}29;
    color: ${({ theme }) => theme.colors.nocfoGreen};
  }

  &.vat_code {
    background: ${({ theme }) => theme.colors.nocfoBlue}29;
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }

  &.credit {
    background: ${({ theme }) => theme.colors.nocfoYellow}29;
    color: ${({ theme }) => theme.colors.nocfoYellow};
  }

  &.debet {
    background: ${({ theme }) => theme.colors.nocfoYellow}29;
    color: ${({ theme }) => theme.colors.nocfoYellow};
  }
`

const StyledCardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 0.6rem;
`

const StyledFlipButton = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing.lg}rem;
  top: ${({ theme }) => theme.spacing.lg}rem;

  svg {
    fill: ${({ theme }) => theme.colors.metalGray};
    width: 1.2rem;
    height: 1.2rem;
  }
`

const StyledBackButton = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md}rem;
  padding-bottom: ${({ theme }) => theme.spacing.lg}rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGray};

  svg {
    fill: ${({ theme }) => theme.colors.metalGray};
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
`

const StyledImportData = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md}rem;

  h4 {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    color: ${({ theme }) => theme.colors.neutralBlack};
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }
`

const AttachmentSelectorWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.md}rem;
`
