import { InlineTeaserView } from '@components'
import { FaInfoCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

export const AlgorithmTrialInfo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <InlineTeaserView
      icon={<FaInfoCircle />}
      iconText={t('document.trialRestriction.title')}
      text={t('document.trialRestriction.description')}
    />
  )
}
