import { PublicClientApplication } from '@azure/msal-browser'
import { useEffect, useMemo } from 'react'
import { useGoogleLogin as useGoogleLoginLib, useGoogleOneTapLogin } from '@react-oauth/google'
import { useQueryParams } from '@hooks'
import { network } from '@utils/network'
import { useMutation } from 'react-query'

/* ===========================
   OAuth Provider Configuration
   =========================== */
export const OAUTH_CONFIG = {
  google: {
    clientId: '60703500575-jhk6v1n2ifotr15mimfmv9kmhsah4q58.apps.googleusercontent.com'
  },
  microsoft: {
    clientId: 'fbe408c8-4ccc-4ae6-a4ef-0ac7d828217c'
  }
}

/* ===========================
   MSAL Client Initialization
   =========================== */
export const msalClientApp = new PublicClientApplication({
  auth: {
    clientId: OAUTH_CONFIG.microsoft.clientId,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
})

/* ===========================
   OAuth Success Mutation
   =========================== */

interface OnSuccessProps {
  state?: string
  code?: string
  accessToken?: string
  idToken?: string
  connectUrl: string
}

// useOnSuccessMutation calls the backend connect endpoint and handles redirection.
export const useOnSuccessMutation = () =>
  useMutation<OnSuccessProps, unknown, OnSuccessProps>(
    (payload: OnSuccessProps) =>
      network.httpClient
        .post(payload.connectUrl, {
          access_token: payload.accessToken,
          code: payload.code,
          id_token: payload.idToken
        })
        .then(({ data }) => {
          network.setAccessToken(data.key)
          return payload
        }),
    {
      onSuccess: (payload: OnSuccessProps) => {
        try {
          const state = JSON.parse(payload.state)
          const next = state?.next || '/'
          window.location.assign(next)
        } catch (error) {
          console.error('Error parsing state:', error)
        }
      }
    }
  )

/* ===========================
   OAuth Hooks for Providers
   =========================== */

export const useMicrosoftLogin = ({
  onError,
  next
}: {
  onError?: (error: any) => void
  next?: string
}) => {
  const mutation = useOnSuccessMutation()

  useEffect(() => {
    msalClientApp
      .handleRedirectPromise()
      .then(response => {
        if (mutation.isLoading) return
        if (!response) return

        mutation.mutate({
          accessToken: response.accessToken,
          idToken: response.idToken,
          state: response.state,
          connectUrl: '/auth/dj-rest-auth/microsoft/connect'
        })
      })
      .catch(onError)
  }, [])

  const state = useMemo(() => JSON.stringify({ next }), [next])

  const login = () =>
    msalClientApp.loginRedirect({
      scopes: ['User.Read'],
      prompt: 'select_account',
      redirectUri: window.location.origin,
      state
    })

  return { login, isLoading: mutation.isLoading || mutation.isSuccess }
}

export const useGoogleLogin = ({ onError, next }: { onError?: () => void; next?: string }) => {
  const mutation = useOnSuccessMutation()
  const [params] = useQueryParams()

  useEffect(() => {
    if (mutation.isLoading) return
    if (params?.code) {
      mutation.mutate({
        code: params.code as string,
        state: params?.state as string,
        connectUrl: '/auth/dj-rest-auth/google/connect'
      })
    }
  }, [params?.code])

  const state = useMemo(() => JSON.stringify({ next }), [next])

  useGoogleOneTapLogin({
    onSuccess: ({ credential }) => {
      if (mutation.isLoading) return
      mutation.mutate({
        accessToken: credential,
        state,
        connectUrl: '/auth/dj-rest-auth/google/connect'
      })
    }
  })

  const login = useGoogleLoginLib({
    ux_mode: 'redirect',
    flow: 'auth-code',
    scope: 'openid email',
    redirect_uri: window.location.origin,
    state: state,
    onError: () => {
      onError?.()
    }
  })

  return { login, isLoading: mutation.isLoading || mutation.isSuccess }
}

/* ==========================
   Mobile App Login Detection
   ========================== */

export interface MobileAppLoginProps {
  mobileRedirectUri: string
  provider: string
}

export const useDetectMobileAppLogin = (): [boolean, MobileAppLoginProps] => {
  // Embeddable mobile login if the following query params are set:
  // ?mobileApp=true&mobileRedirectUri=...&provider=google|microsoft

  const [queryParams] = useQueryParams()

  const { mobileApp, mobileRedirectUri, provider } = {
    mobileApp: 'false',
    mobileRedirectUri: undefined,
    provider: undefined,
    ...queryParams
  }

  return [mobileApp === 'true', { mobileRedirectUri, provider }]
}
