import { DoubleColumnLayout, DoubleColumnLayoutColumn } from '@components'
import { UserRole } from '@constants'
import { FeatureWrapper, PermissionBoundary, useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaAward,
  FaBook,
  FaCalculator,
  FaChevronRight,
  FaCog,
  FaGlobeEurope,
  FaSyncAlt,
  FaUsers
} from 'react-icons/fa'
import styled from 'styled-components'
import {
  BusinessSettings,
  DefaultAccountSettings,
  EInvoicingSettings,
  IntegrationSettings,
  InvoicingSettings,
  PeopleSettings,
  PlanSettings,
  EInvoicingDemoSettings
} from './sections'
import holviImg from '@assets/holvi-icon.svg'
import { HolviIntegrationSettings } from '@pages/SettingsPage/sections/HolviIntegrationSettings'
import { BusinessForms } from '@root/query'
import { FaDownload } from 'react-icons/fa6'
import { DataExport } from '@pages/SettingsPage/sections/DataExport.tsx'

export const SettingsPage: React.FC = () => {
  const [t] = useTranslation()
  const [section, setSection] = useQueryParam('page')
  const {
    data: { form }
  } = useBusinessContext()

  const isDemoBusiness = form === BusinessForms.DEMO

  const MenuLink = React.memo<{ icon: React.ReactNode; name: string; link: string }>(
    ({ icon, name, link }) => (
      <SettingsPageSelector
        href="#"
        onClick={e => {
          setSection(link)
          e.preventDefault()
        }}
        className={classNames({ active: section === link })}
      >
        <span className="icon-wrapper">{icon}</span>
        <span className="item-name">{name}</span> <FaChevronRight />
      </SettingsPageSelector>
    )
  )

  return (
    <DoubleColumnLayout
      header={t('settings.mainTitle')}
      isRightVisible={Boolean(section)}
      onRightClose={() => setSection(undefined)}
    >
      <DoubleColumnLayoutColumn innerKey="settings">
        <SettingsPageContent>
          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <MenuLink icon={<FaCog />} name={t('settings.sections.general')} link="settings" />
            <MenuLink
              icon={<FaBook />}
              name={t('settings.sections.defaultAccounts')}
              link="accounts"
            />
          </PermissionBoundary>

          <MenuLink icon={<FaUsers />} name={t('settings.sections.people')} link="people" />

          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <MenuLink
              icon={<FaCalculator />}
              name={t('settings.sections.invoicing')}
              link="invoicing"
            />

            <PermissionBoundary requireRole={UserRole.ADMIN}>
              <FeatureWrapper
                feature="feature_show_received_einvoices"
                alternativeContent={() => {
                  return (
                    isDemoBusiness && (
                      <MenuLink
                        icon={<FaGlobeEurope />}
                        name={t('settings.sections.einvoicing')}
                        link="einvoicing_demo"
                      />
                    )
                  )
                }}
              >
                <MenuLink
                  icon={<FaGlobeEurope />}
                  name={t('settings.sections.einvoicing')}
                  link="einvoicing"
                />
              </FeatureWrapper>
            </PermissionBoundary>

            <MenuLink
              icon={<FaSyncAlt />}
              name={t('settings.sections.integrations')}
              link="integrations"
            />

            <MenuLink
              icon={<img src={holviImg} />}
              name={t('settings.sections.holviIntegration')}
              link="holvi"
            />
          </PermissionBoundary>

          <PermissionBoundary requireRole={UserRole.ADMIN}>
            <FeatureWrapper feature="is_billable">
              <MenuLink icon={<FaAward />} name={t('settings.sections.plan')} link="plan" />
            </FeatureWrapper>
          </PermissionBoundary>

          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <MenuLink
              icon={<FaDownload />}
              name={t('settings.sections.dataExport')}
              link="data-export"
            />
          </PermissionBoundary>
        </SettingsPageContent>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn isRight={true} innerKey={section}>
        <PermissionBoundary requireRole={UserRole.EDITOR}>
          {section === 'settings' && <BusinessSettings />}
          {section === 'invoicing' && <InvoicingSettings />}

          <FeatureWrapper
            feature="feature_show_received_einvoices"
            alternativeContent={() => {
              return section === 'einvoicing_demo' && isDemoBusiness && <EInvoicingDemoSettings />
            }}
          >
            {section === 'einvoicing' && <EInvoicingSettings />}
          </FeatureWrapper>

          {section === 'accounts' && <DefaultAccountSettings />}
        </PermissionBoundary>

        {section === 'people' && <PeopleSettings />}

        <PermissionBoundary requireRole={UserRole.EDITOR}>
          {section === 'integrations' && <IntegrationSettings />}
          {section === 'holvi' && <HolviIntegrationSettings />}
        </PermissionBoundary>

        <PermissionBoundary requireRole={UserRole.ADMIN}>
          <FeatureWrapper feature="is_billable">
            {section === 'plan' && <PlanSettings />}
          </FeatureWrapper>
        </PermissionBoundary>

        {section === 'data-export' && <DataExport />}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const SettingsPageContent = styled.div`
  height: 100%;
  overflow: auto;
`

const SettingsPageSelector = styled.a`
  padding: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  color: ${({ theme }) => theme.colors.neutralBlack};
  background: white;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHover};
    color: ${({ theme }) => theme.colors.neutralBlack};
    text-decoration: none;
  }

  &.active {
    background: ${({ theme }) => theme.colors.defaultHover};
  }

  &:not(:last-of-type) {
    border-bottom: 0.5px solid #f6f7f9;
  }

  &:last-of-type {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:first-of-type {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper svg,
  .icon-wrapper img {
    font-size: ${({ theme }) => theme.iconSize.xs}rem;
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
    color: ${({ theme }) => theme.colors.iconGrayDark};
  }

  .item-name {
    flex: 1;
  }
`
