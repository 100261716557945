import { network } from '@utils'
import {
  ActionFunction,
  handleReturn,
  MutationFunction,
  PaginatedResponse,
  QueryFunction
} from './root'

export interface IVatPeriod {
  id: number
  period: number
  start_date: string
  end_date: string
  due_date: string
  is_locked: boolean
  is_reported: boolean
  has_due_passed: boolean
  has_ended: boolean
}

interface VatPeriodListProps {
  businessId: string
}

export const fetchVatPeriods: QueryFunction<
  VatPeriodListProps,
  PaginatedResponse<IVatPeriod>
> = async ({ businessId }, params = null) => {
  const url = `/v1/business/${businessId}/vat_period/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface VatPeriodInstanceProps {
  businessId: string
  vatPeriodId: number
}

export const fetchVatPeriod: QueryFunction<VatPeriodInstanceProps, IVatPeriod> = async (
  { businessId, vatPeriodId },
  params = null
) => {
  const url = `/v1/business/${businessId}/vat_period/${vatPeriodId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createVatPeriod: MutationFunction<VatPeriodListProps, IVatPeriod> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/vat_period/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData
  })
  return data
}

export const updateVatPeriod: MutationFunction<VatPeriodInstanceProps, IVatPeriod> = async (
  { businessId, vatPeriodId },
  requestData
) => {
  const url = `/v1/business/${businessId}/vat_period/${vatPeriodId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteVatPeriod: MutationFunction<VatPeriodInstanceProps, void> = async ({
  businessId,
  vatPeriodId
}) => {
  const url = `/v1/business/${businessId}/vat_period/${vatPeriodId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

export const lockVatPeriod: MutationFunction<VatPeriodInstanceProps, void> = async ({
  businessId,
  vatPeriodId
}) => {
  const url = `/v1/business/${businessId}/vat_period/${vatPeriodId}/action/lock/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const reportVatPeriod: MutationFunction<VatPeriodInstanceProps, void> = async ({
  businessId,
  vatPeriodId
}) => {
  const url = `/v1/business/${businessId}/vat_period/${vatPeriodId}/action/report/`
  await network.httpClient.request({
    url,
    method: 'POST'
  })
}

export interface IVatReporterContactDetails {
  verohallinto_latest_reporter_full_name: string
  verohallinto_latest_reporter_phone_number: string
}

export const reportVatPeriodToVerohallinto: ActionFunction<
  VatPeriodInstanceProps,
  IVatReporterContactDetails,
  IVatPeriod
> = async ({ businessId, vatPeriodId }, reporterDetails) => {
  const url = `/v1/business/${businessId}/vat_period/${vatPeriodId}/action/report_to_verohallinto/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: {
      verohallinto_latest_reporter_full_name:
        reporterDetails.verohallinto_latest_reporter_full_name,
      verohallinto_latest_reporter_phone_number:
        reporterDetails.verohallinto_latest_reporter_phone_number
    },
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface VatPeriodAuthorizationProps {
  businessId: string
}

interface VatPeriodAuthorizationResponse {
  ok: boolean
}

export const checkVatReportingAuthorization: QueryFunction<
  VatPeriodAuthorizationProps,
  VatPeriodAuthorizationResponse
> = async ({ businessId }) => {
  const url = `/v1/business/${businessId}/vat_period/has_suomi_authentication/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
